import React, { useEffect } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loading from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Badge, Table } from "react-bootstrap";
import { Message, Button, IconButton, Icon, Tag } from "rsuite";
import Search from "../../../components/admin/Search";
import pathImages from "../../../constants/pathImages";

import NoPhoto from "../../../assets/img/no-photos.png";
const API = new APLICLASS();

const PostsLista = () => {
  const [loader, setLoader] = useStateIfMounted(true);
  const [posts, setPosts] = useStateIfMounted([]);
  const [emptySearchResult, setEmptySearchResult] = useStateIfMounted(false);
  const [postsFiltrados, setPostsFiltrados] = useStateIfMounted([]);
  const [search, setSearch] = useStateIfMounted("");

  const navigation = useNavigation();

  useEffect(() => {
    _getPosts();
  }, []);

  const _getPosts = async () => {
    const { data } = await API.getPosts();
    setPosts(data);
    setPostsFiltrados(data);
    setLoader(false);
  };

  const _search = () => {
    setEmptySearchResult(false);
    if (search != "") {
      let resFiltrados = posts.filter((item) => {
        return item.cliente_nombre.toLowerCase().includes(search.toLowerCase());
      });
      if (resFiltrados.length > 0) {
        setPostsFiltrados(resFiltrados);
      } else {
        setEmptySearchResult(true);
      }
    } else {
      setPostsFiltrados(posts);
    }
  };

  useEffect(() => {
    _search();
  }, [search]);

  const _setSearch = (val) => {
    setSearch(val);
  };

  const ListaPosts = () => {
    if (postsFiltrados.length > 0) {
      return (
        <div>
          {!emptySearchResult ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Imagen</th>
                  <th>Cliente</th>
                  <th>Vehículo </th>
                  <th>Marca </th>
                  <th>Activo</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {postsFiltrados.map((item, index) => {
                  return (
                    <tr>
                      <td
                        style={{
                          backgroundImage: `url(${
                            pathImages + item.imagen_portada
                          })`,
                          width: "50px",
                          height: "50px",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></td>
                      <td>{item.cliente_nombre}</td>
                      <td>{item.vehiculo_nombre}</td>
                      <td>{item.marca_nombre}</td>

                      <td>
                        {item.estatus == 1 ? (
                          <Tag color="green">Activo</Tag>
                        ) : (
                          <Tag
                            color="black"
                            style={{ background: "black", color: "white" }}
                          >
                            Deshabilitado
                          </Tag>
                        )}
                      </td>

                      <td>
                        <IconButton
                          icon={<Icon icon="edit" />}
                          circle
                          size="sm"
                          color="primary"
                          onClick={() =>
                            navigation._replace(`/posts/${item.id}`)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div>No econtramos registros que coincidan con tu búsqueda</div>
          )}
        </div>
      );
    } else {
      return (
        <Message
          type="info"
          title="Info"
          showIcon
          description={
            <p>
              No se han agregado posts aún.
              <br />
            </p>
          }
        />
      );
    }
  };

  return (
    <MainLayout
      section="Posts"
      bread={[
        {
          link: "/",
          name: "Inicio",
          active: false,
        },
        {
          link: false,
          name: "Posts",
          active: true,
        },
      ]}
    >
      <SectionTitle title="Posts" caption="Lista"></SectionTitle>
      <div className="bar-actions">
        <div className="bar-actions__left">
          <Search value={search} updateInputFunction={_setSearch}></Search>
        </div>
        <div className="bar-actions__right"></div>
      </div>
      {loader ? (
        <Loading text="Cargando posts"></Loading>
      ) : (
        <ListaPosts></ListaPosts>
      )}
    </MainLayout>
  );
};

export default PostsLista;
