import React, { useEffect } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loading from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Badge, Table } from "react-bootstrap";
import { Message, Button, IconButton, Icon, Tag } from "rsuite";
import Search from "../../../components/admin/Search";
import pathImages from "../../../constants/pathImages";
import NoPhoto from "../../../assets/img/no-photos.png";
import color from "../../../constants/color";
const API = new APLICLASS();

const ClientesLista = () => {
  const [loader, setLoader] = useStateIfMounted(true);
  const [clientes, setClientes] = useStateIfMounted([]);
  const [emptySearchResult, setEmptySearchResult] = useStateIfMounted(false);
  const [clientesFiltrados, setClientesFiltrados] = useStateIfMounted([]);
  const [search, setSearch] = useStateIfMounted("");

  const navigation = useNavigation();

  useEffect(() => {
    _getClientes();
  }, []);

  const _getClientes = async () => {
    const { data } = await API.getClientes();
    if (data !== false) {
      if (Array.isArray(data)) {
        setClientes(data);
        setClientesFiltrados(data);
      }
    }

    setLoader(false);
  };

  const _search = () => {
    setEmptySearchResult(false);
    if (search != "") {
      let resFiltrados = clientes.filter((item) => {
        return item.nombre.toLowerCase().includes(search.toLowerCase());
      });
      if (resFiltrados.length > 0) {
        setClientesFiltrados(resFiltrados);
      } else {
        setEmptySearchResult(true);
      }
    } else {
      setClientesFiltrados(clientes);
    }
  };

  useEffect(() => {
    _search();
  }, [search]);

  const _setSearch = (val) => {
    setSearch(val);
  };

  const ListaClientes = () => {
    if (clientesFiltrados.length > 0) {
      return (
        <div>
          {!emptySearchResult ? (
            <Table striped hover>
              <thead>
                <tr>
                  <th>Nombre</th>

                  <th>Email</th>
                  <th>Teléfono</th>
                  <th>País</th>
                  <th>Estado</th>
                  <th>Ciudad</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {clientesFiltrados.map((item, index) => {
                  return (
                    <tr>
                      <td
                        style={{
                          paddingTop: 25,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item.estatus == 2 && (
                          <Icon
                            icon="minus-circle"
                            style={{ color: "red", marginRight: 5 }}
                            size="lg"
                          />
                        )}
                        <span>
                          {item.nombre} {item.apellidos}
                        </span>
                      </td>
                      <td style={{ paddingTop: 25 }}>{item.email}</td>

                      <td style={{ paddingTop: 22 }}>{item.telefono}</td>

                      <td style={{ paddingTop: 22 }}>{item.pais}</td>
                      <td style={{ paddingTop: 22 }}>{item.estado}</td>
                      <td style={{ paddingTop: 22 }}>{item.ciudad}</td>

                      <td style={{ paddingTop: 20 }}>
                        <IconButton
                          icon={<Icon icon="eye" color="white" />}
                          circle
                          size="sm"
                          onClick={() =>
                            navigation._replace(`/clientes/detalle/${item.id}`)
                          }
                          style={{
                            marginRight: 10,
                            background: color.primary,
                            color: "white",
                          }}
                        />
                        <IconButton
                          icon={<Icon icon="edit" />}
                          circle
                          size="sm"
                          color="primary"
                          onClick={() =>
                            navigation._replace(`/clientes/${item.id}`)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div>No econtramos registros que coincidan con tu búsqueda</div>
          )}
        </div>
      );
    } else {
      return (
        <Message
          type="info"
          title="Info"
          showIcon
          description={<p>No se han registrado clientes</p>}
        />
      );
    }
  };

  return (
    <MainLayout>
      <SectionTitle title="Clientes" caption="Lista"></SectionTitle>
      <div className="bar-actions">
        <div className="bar-actions__left">
          <Search value={search} updateInputFunction={_setSearch}></Search>
        </div>
        <div className="bar-actions__right">
          <Button
            appearance="primary"
            onClick={() => navigation._navigate("/clientes/agregar")}
          >
            Agregar nuevo cliente
          </Button>
        </div>
      </div>
      {loader ? (
        <Loading text="Cargando clientes"></Loading>
      ) : (
        <ListaClientes></ListaClientes>
      )}
    </MainLayout>
  );
};

export default ClientesLista;
