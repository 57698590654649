import React, { useEffect } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loading from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import imagePath from "../../../constants/pathImages";
import { Badge, Table } from "react-bootstrap";
import {
  Message,
  Button,
  IconButton,
  Icon,
  Tag,
  SelectPicker,
  Alert,
  DatePicker,
  Dropdown,
} from "rsuite";
import "moment/locale/es";
import moment from "moment";
import Search from "../../../components/admin/Search";
import ImageDefault from "../../../assets/img/default.jpg";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import color from "../../../constants/color";
import VentasInfo from "../../../components/admin/Ventas";
import APIWORLDCLASS from "../../../api/world";

const API = new APLICLASS();
const APIWORLD = new APIWORLDCLASS();

const VentasLista = () => {
  const [loader, setLoader] = useStateIfMounted(true);
  const [categoriaFiltro, setCategoriaFiltro] = useStateIfMounted(false);

  const [ventas, setVentas] = useStateIfMounted([]);
  const [emptySearchResult, setEmptySearchResult] = useStateIfMounted(false);
  const [ventasFiltradas, setVentasFiltradas] = useStateIfMounted([]);
  const [tipoReporte, setTipoReporte] = useStateIfMounted("Mes");
  const [tipoEstatus, setTipoEstatus] = useStateIfMounted("All");
  const [tipoEstado, setTipoEstado] = useStateIfMounted("All");
  const [estados, setEstados] = useStateIfMounted([]);

  const [numeroVentas, setNumeroVentas] = useStateIfMounted("");
  const [ventasTotales, setVentasTotales] = useStateIfMounted("");
  const [productoMasVendido, setProductoMasVendido] = useStateIfMounted("");

  const [search, setSearch] = useStateIfMounted("");

  const navigation = useNavigation();

  useEffect(() => {
    _getVentasByFiltros();
  }, [tipoReporte]);

  const _getVentasByFiltros = () => {
    switch (tipoReporte) {
      case "Mes":
        return _getVentasMes();

      case "Hoy":
        return _getVentasDia();

      case "Semana":
        return _getVentasSemana();

      case "Quincena":
        return _getVentasQuincena();

      case "Historial":
        return _getVentasHistorial();

      default:
        return null;
    }
  };

  useEffect(() => {
    _getEstados();
  }, [tipoEstado]);

  const _getEstados = async () => {
    setEstados([]);
    const { data } = await APIWORLD.getEstadosDelPais(233);
    if (data !== false) {
      const estadosArr = [];
      let All = {
        label: "All",
        value: "All",
      };

      estadosArr.push(All);
      data.map((item) => {
        let itemData = {
          label: item.name,
          value: item.name,
        };

        estadosArr.push(itemData);
      });

      setEstados(estadosArr);
    }
  };

  const _getVentasMes = async () => {
    setLoader(true);
    const fecha = moment().format("Y-MM-DD");
    const { data } = await API.getVentasHistorial(fecha);

    if (Array.isArray(data.Ventas)) {
      setVentas(data.Ventas);
      setVentasFiltradas(data.Ventas);
      setNumeroVentas(data.Total_Ventas);
      setVentasTotales(data.Monto);
      if (data.ProductoMasVendido.length > 0) {
        setProductoMasVendido(data.ProductoMasVendido[0]);
      } else {
        setProductoMasVendido("");
      }
    }

    setLoader(false);
  };

  const _getVentasDia = async () => {
    setLoader(true);
    const fecha = moment().format("Y-MM-DD");
    const { data } = await API.getVentasDelDia(fecha);

    if (Array.isArray(data.Ventas)) {
      setVentas(data.Ventas);
      setVentasFiltradas(data.Ventas);
      setNumeroVentas(data.Total_Ventas);
      setVentasTotales(data.Monto);
      if (data.ProductoMasVendido.length > 0) {
        setProductoMasVendido(data.ProductoMasVendido[0]);
      } else {
        setProductoMasVendido("");
      }
    }

    setLoader(false);
  };

  const _getVentasSemana = async () => {
    setLoader(true);
    const fecha = moment().format("Y-MM-DD");
    const { data } = await API.getVentasDeLaSemana(fecha);

    if (Array.isArray(data.Ventas)) {
      setVentas(data.Ventas);
      setVentasFiltradas(data.Ventas);
      setNumeroVentas(data.Total_Ventas);
      setVentasTotales(data.Monto);
      if (data.ProductoMasVendido.length > 0) {
        setProductoMasVendido(data.ProductoMasVendido[0]);
      } else {
        setProductoMasVendido("");
      }
    }

    setLoader(false);
  };

  const _getVentasQuincena = async () => {
    setLoader(true);
    const fecha = moment().format("Y-MM-DD");
    const { data } = await API.getVentasDeLaQuincena(fecha);

    if (Array.isArray(data.Ventas)) {
      setVentas(data.Ventas);
      setVentasFiltradas(data.Ventas);
      setNumeroVentas(data.Total_Ventas);
      setVentasTotales(data.Monto);
      if (data.ProductoMasVendido.length > 0) {
        setProductoMasVendido(data.ProductoMasVendido[0]);
      } else {
        setProductoMasVendido("");
      }
    }

    setLoader(false);
  };

  const _getVentasHistorial = async () => {
    setLoader(true);
    const fecha = moment().format("Y-MM-DD");
    const { data } = await API.getVentasHistorial(fecha);

    if (Array.isArray(data.Ventas)) {
      setVentas(data.Ventas);
      setVentasFiltradas(data.Ventas);
      setNumeroVentas(data.Total_Ventas);
      setVentasTotales(data.Monto);
      if (data.ProductoMasVendido.length > 0) {
        setProductoMasVendido(data.ProductoMasVendido[0]);
      } else {
        setProductoMasVendido("");
      }
    }

    setLoader(false);
  };

  const _search = () => {
    setEmptySearchResult(false);
    if (search != "") {
      let resFiltrados = ventas.filter((item) => {
        let nombreCompleto = item.nombre + " " + item.apellidos;
        return nombreCompleto.toLowerCase().includes(search.toLowerCase());
      });
      if (resFiltrados.length > 0) {
        setVentasFiltradas(resFiltrados);
      } else {
        setEmptySearchResult(true);
      }
    } else {
      setVentasFiltradas(ventas);
    }
  };

  useEffect(() => {
    _filtrarPorEstatus();
  }, [tipoEstatus]);

  const _filtrarPorEstatus = () => {
    setEmptySearchResult(false);

    if (tipoEstatus != "All") {
      let resFiltrados = ventas.filter((item) => {
        return item.estatus == tipoEstatus;
      });
      if (resFiltrados.length > 0) {
        setVentasFiltradas(resFiltrados);
      } else {
        setEmptySearchResult(true);
      }
    } else {
      setVentasFiltradas(ventas);
    }
  };

  useEffect(() => {
    _filtrarPorEstado();
  }, [tipoEstado]);

  const _filtrarPorEstado = () => {
    setEmptySearchResult(false);

    if (tipoEstado != "All") {
      let resFiltrados = ventas.filter((item) => {
        return item.estado == tipoEstado;
      });
      if (resFiltrados.length > 0) {
        setVentasFiltradas(resFiltrados);
      } else {
        setEmptySearchResult(true);
      }
    } else {
      setVentasFiltradas(ventas);
    }
  };

  useEffect(() => {
    _search();
  }, [search]);

  const _setSearch = (val) => {
    setSearch(val);
  };

  const ListaVentas = () => {
    if (ventasFiltradas.length > 0) {
      return (
        <div>
          {!emptySearchResult ? (
            <div>
              <Table id="ventasExcel" striped hover>
                <thead>
                  <tr>
                    <th>UID</th>
                    <th>Cliente</th>
                    <th>Fecha</th>
                    <th>Envío</th>
                    <th>Estado</th>
                    <th>Total</th>
                    <th>Estatus</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {ventasFiltradas.map((item, index) => {
                    return (
                      <tr>
                        <td>{item.uid}</td>
                        <td>
                          {item.nombre} {item.apellidos}
                        </td>
                        <td>{item.created_at}</td>
                        <td>${item.shipping_total}</td>
                        <td>{item.estado}</td>
                        <td>${item.total}</td>

                        <td>{item.estatus}</td>

                        <td>
                          <IconButton
                            icon={<Icon icon="eye" color="white" />}
                            style={{ background: color.primary }}
                            circle
                            size="sm"
                            color="primary"
                            onClick={() =>
                              navigation._replace(`/ventas/${item.id}/info`)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <div>No econtramos registros que coincidan con tu búsqueda</div>
          )}
        </div>
      );
    } else {
      return (
        <Message
          type="info"
          title="Info"
          showIcon
          description={<p>No se han agregado ventas aún</p>}
        />
      );
    }
  };

  return (
    <MainLayout
      section="Ventas"
      bread={[
        {
          link: "/",
          name: "Inicio",
          active: false,
        },
        {
          link: false,
          name: "Ventas",
          active: true,
        },
      ]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <SectionTitle
          title="Ventas"
          caption={"Lista de todas las ventas"}
        ></SectionTitle>
        {/* <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success"
          table="reporteProduccion"
          filename="Ventas DMXPERFORMANCE"
          sheet="ventas"
          buttonText="Descargar archivo de Excel"
        /> */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="input__item">
            <span>Tipo de reporte</span>
            <SelectPicker
              searchable={false}
              closable={false}
              style={{ width: 150 }}
              value={tipoReporte}
              onChange={(val) => setTipoReporte(val)}
              data={[
                {
                  label: "Hoy",
                  value: "Hoy",
                },
                {
                  label: "Semana",
                  value: "Semana",
                },
                {
                  label: "Quincena",
                  value: "Quincena",
                },
                {
                  label: "Mes",
                  value: "Mes",
                },
                {
                  label: "Historial",
                  value: "Historial",
                },
                // {
                //   label: "Rango de fechas",
                //   value: "Rango",
                // },
              ]}
              placeholder="Selecciona el tipo de reporte"
            />
          </div>

          <Dropdown
            style={{ marginTop: 40, marginLeft: 10 }}
            appearance="default"
            title="Reportes especiales"
          >
            <Dropdown.Item
              onClick={() => navigation._navigate(`/reportes/produccion`)}
            >
              Ver reporte de Producción
            </Dropdown.Item>

            <Dropdown.Item
              onClick={() => navigation._navigate(`/reportes/general`)}
            >
              Ver reporte General
            </Dropdown.Item>
          </Dropdown>

          {tipoReporte == "Rango" && (
            <div style={{ display: "flex", marginLeft: 20 }}>
              <div className="input__item">
                <span>Fecha de inicio</span>
                <DatePicker style={{ width: 150 }} />
              </div>

              <div className="input__item">
                <span>Fecha fin</span>
                <DatePicker style={{ width: 150 }} />
              </div>
            </div>
          )}

          {/* <div style={{ marginLeft: 20, marginTop: 42 }}>
            <Button appearance="secondary" onClick={() => null}>
              Filtrar
            </Button>
          </div> */}
        </div>
      </div>

      <VentasInfo
        mb
        mt
        total={ventasTotales}
        productoMasVendido={productoMasVendido}
        numeroVentas={numeroVentas}
        tipoReporte={tipoReporte}
      ></VentasInfo>

      <div className="bar-actions">
        <div className="bar-actions__left">
          <Search value={search} updateInputFunction={_setSearch}></Search>
          <div style={{ marginLeft: 10 }}>
            <SelectPicker
              searchable={false}
              cleanable={false}
              style={{ width: 150 }}
              value={tipoEstatus}
              onChange={(val) => setTipoEstatus(val)}
              data={[
                {
                  label: "All",
                  value: "All",
                },
                {
                  label: "Processing",
                  value: "Processing",
                },
                {
                  label: "Confirmed",
                  value: "Confirmed",
                },
                {
                  label: "Sent",
                  value: "Sent",
                },
                {
                  label: "Cancelled",
                  value: "Cancelled",
                },
              ]}
              placeholder="Selecciona el tipo de Estatus"
            />
          </div>

          <div style={{ marginLeft: 10 }}>
            <SelectPicker
              data={estados}
              appearance="default"
              placeholder="Select the State"
              cleanable={false}
              value={tipoEstado}
              onChange={(val) => setTipoEstado(val)}
              searchable={true}
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div
          className="bar-actions__right"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button
            appearance="primary"
            onClick={() => navigation._navigate("/punto-de-venta")}
          >
            Agregar venta
          </Button>
        </div>
      </div>

      {loader ? (
        <Loading text="Cargando ventas"></Loading>
      ) : (
        <div>
          <ListaVentas></ListaVentas>
        </div>
      )}
    </MainLayout>
  );
};

export default VentasLista;
