import React from "react";
import { Col, Container, Grid, Row } from "rsuite";
import MenuTop from "../TopMenu";

const FullLayout = (props) => {
  return (
    <div className="main">
      <MenuTop section={props.section} bread={props.bread}></MenuTop>
      <Grid fluid>
        <Container>
          <Row>
            <Col xs={24}>
              <div className="main-content">{props.children}</div>
            </Col>
          </Row>
        </Container>
      </Grid>
    </div>
  );
};

export default FullLayout;
