import React, { useContext } from "react";
import { Alert, Button, Col, Input, Notification, Row } from "rsuite";
import { useStateIfMounted } from "use-state-if-mounted";
import APLICLASS from "../../../api/web";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import useNavigation from "../../../hooks/useNavigation";
import UserContext from "../../../state/user";

const API = new APLICLASS();

const Login = () => {
  const { user, setUser } = useContext(UserContext);
  const [loader, setLoader] = useStateIfMounted(false);

  // **********************************************************
  const [email, setEmail] = useStateIfMounted("");
  const [password, setPassword] = useStateIfMounted("");

  const _validate = () => {
    if (email == "" || password == "") {
      Notification["error"]({
        title: "Error en el formulario",
        description: <span>Verifica los datos ingresados</span>,
      });
    } else {
      _login();
    }
  };

  const _login = async () => {
    setLoader(true);
    const { data } = await API.loginAdmin(email, password);
    if (data == false) {
      Notification["error"]({
        title: "Error al inciar sesión",
        description: (
          <span>Las credenciales proporcionadas no son válidas</span>
        ),
      });
    } else {
      localStorage.setItem("id", data.id);
      setUser(data);
    }
    setLoader(false);
  };

  return (
    <MainLayout noHeader>
      <SectionTitle
        title="Iniciar Sesión"
        caption="Ingresa los campos requeridos para acceder al sistema"
      ></SectionTitle>

      <Row gutter={48}>
        <Col xs={24}>
          <div className="input__item">
            <span>Email</span>
            <Input
              placeholder="Email"
              value={email}
              onChange={(val) => setEmail(val)}
            />
          </div>

          <div className="input__item">
            <span>Password</span>
            <Input
              placeholder="Password"
              value={password}
              type="password"
              onChange={(val) => setPassword(val)}
            />
          </div>
        </Col>
        <Col xs={24}>
          <div className="input__item" style={{ marginTop: 35 }}>
            <Button
              block
              appearance="primary"
              size="lg"
              onClick={() => _validate()}
              loading={loader ? true : false}
            >
              Iniciar Sesión
            </Button>
          </div>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Login;
