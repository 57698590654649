import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import APICLASS from "../api/web";
import Loader from "../components/admin/Loader";
import UserContext from "../state/user";
import LockedNavigation from "./locked";
import PublicNavigation from "./public";

const API = new APICLASS();

const MainNavigation = (props) => {
  const { user, setUser } = useContext(UserContext);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("id") !== null) {
      _getUser(localStorage.getItem("id"));
    }
  }, []);

  const _getUser = async (id) => {
    setLoader(true);
    const { data } = await API.getUser(id);

    if (data !== false) {
      setUser(data);
    }

    setLoader(false);
  };

  if (loader) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader></Loader>
      </div>
    );
  } else {
    if (user == false) {
      return <PublicNavigation></PublicNavigation>;
    } else {
      return <LockedNavigation></LockedNavigation>;
    }
  }
};

export default MainNavigation;
