import React, { useEffect } from "react";
import {
  Row,
  Col,
  Icon,
  IconButton,
  Tag,
  Uploader,
  Message,
  Notification,
  Alert,
} from "rsuite";
import { Table } from "react-bootstrap";
import { useStateIfMounted } from "use-state-if-mounted";
import api from "../../../constants/api";
import pathImages from "../../../constants/pathImages";
import { Link } from "react-router-dom";

import APLICLASS from "../../../api/web";
import Loading from "../../../components/admin/Loader";
const API = new APLICLASS();

const GaleriaProducto = (props) => {
  const [images, setImages] = useStateIfMounted([]);

  const [loader, setLoader] = useStateIfMounted(false);
  const [loaderUploader, setLoaderUploader] = useStateIfMounted(false);

  useEffect(() => {
    _getGaleria();
  }, []);

  const _getGaleria = async () => {
    setLoader(true);
    const { data } = await API.getGaleriaProducto(props.id);
    setImages(data);
    setLoader(false);
  };

  const _delete = async (id_imagen) => {
    const windowAlert = window.confirm(
      "Estás seguro que deseas eliminar esta imagen?"
    );
    if (windowAlert == true) {
      setLoaderUploader(true);

      let request = {
        id_producto: props.id,
        id_imagen: id_imagen,
      };

      const { data } = await API.deleteImagenGaleriaProducto(request);

      if (data === true) {
        Alert.success("El registro se eliminó exitosamente");

        _getGaleria();
      } else {
        Alert.error("Ocurrió un error al eliminar el registro");
      }

      setLoaderUploader(false);
    }
  };

  const _setDestacada = async (id_imagen) => {
    setLoaderUploader(true);
    let request = {
      id_producto: props.id,
      id_imagen: id_imagen,
    };

    const { data } = await API.asignarImagenDestacadaProductoGaleria(request);
    if (data === true) {
      Alert.success("Se actualizó la imagen de portada");

      _getGaleria();
    } else {
      Alert.error("Ocurrió un error al actualizar la imagen de portada");
    }
    setLoaderUploader(false);
  };

  return (
    <div>
      <h3 style={{ marginTop: 20 }}>Galería de imágenes</h3>
      <Row>
        <Col xs={24}>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
          >
            <span style={{ fontWeight: "bold" }}>Agregar nueva imagen</span>
            <Uploader
              style={{ marginLeft: 10 }}
              fileListVisible={false}
              onUpload={() => {
                setLoaderUploader(true);
              }}
              onSuccess={() => {
                _getGaleria();
                Alert.success("La imagen se guardó exitosamente");
                setLoaderUploader(false);
              }}
              listType="picture"
              action={`${api}`}
              data={{
                ot: "SaveImagenGaleriaProducto",
                app: "admin",
                id_producto: props.id,
              }}
              name="img"
            >
              <button>
                <Icon icon="camera-retro" size="lg" />
              </button>
            </Uploader>
          </div>
        </Col>
        <Col xs={24}>
          <div>
            {images.length > 0 ? (
              <div>
                {loaderUploader ? (
                  <Loading text="Subiendo imagen..."></Loading>
                ) : (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Imagen</th>
                        <th>URL</th>
                        <th>Portada</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {images.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <div
                                style={{
                                  backgroundImage: `url(${pathImages}/${item.url})`,
                                }}
                                className="table__image"
                              ></div>
                            </td>
                            <td style={{ paddingTop: 25 }}>
                              <a
                                href={`${pathImages}/${item.url}`}
                                target="_blank"
                              >
                                {`${pathImages}/${item.url}`}
                              </a>
                            </td>
                            <td style={{ paddingTop: 22 }}>
                              <Tag
                                color={item.portada === "1" ? "cyan" : "red"}
                              >
                                {item.portada === "1" ? "Si" : "No"}
                              </Tag>
                            </td>
                            <td style={{ paddingTop: 19 }}>
                              <IconButton
                                icon={<Icon icon="star" />}
                                circle
                                size="sm"
                                color="blue"
                                onClick={() => _setDestacada(item.id)}
                              />
                              <IconButton
                                style={{ marginLeft: 10 }}
                                icon={<Icon icon="trash" />}
                                circle
                                size="sm"
                                color="red"
                                onClick={() => _delete(item.id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </div>
            ) : (
              <Message
                showIcon
                type="warning"
                title="Warning"
                description={
                  <p>No has agregado imágenes a este producto aún</p>
                }
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GaleriaProducto;
