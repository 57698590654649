import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import pathImages from "../../../constants/pathImages";
import { Tag } from "rsuite";

import { Schema } from "rsuite";
import {
  Checkbox,
  CheckboxGroup,
  CheckPicker,
  Row,
  Col,
  Grid,
  Message,
  SelectPicker,
  Input,
  InputNumber,
  Button,
  Notification,
  Alert,
} from "rsuite";
import Loader from "../../../components/admin/Loader";
import { Toggle } from "rsuite";
import { useParams } from "react-router-dom";

import Search from "../../../components/admin/Search";
import ErrorInput from "../../../components/admin/ErrorInput";
const API = new APLICLASS();

const PostEditar = () => {
  const navigation = useNavigation();
  const [loader, setLoader] = useStateIfMounted(false);
  const [post, setPost] = useStateIfMounted(false);

  const { id } = useParams();

  useEffect(() => {
    _getPost();
  }, []);

  const _getPost = async () => {
    setLoader(true);
    const { data } = await API.getPost(id);
    if (data !== false) {
      setPost(data);
    }
    setLoader(false);
  };

  const _update = async (estatus) => {
    setLoader(true);

    let request = {
      estatus: estatus,
      id: id,
    };

    const { data } = await API.updatePost(request);

    if (data === true) {
      Alert.success("Registro actualizado exitosamente.");
      navigation._navigate(`/posts/`);
    } else {
      Alert.error("Hubo un error al actualizar el registro.");
    }

    setLoader(false);
  };

  return (
    <MainLayout>
      <SectionTitle title="Post"></SectionTitle>

      {loader ? (
        <Loader text="Cargando datos..."></Loader>
      ) : (
        <Row>
          <Col xs={12}>
            <img
              style={{ width: "100%" }}
              src={pathImages + post.imagen_portada}
            ></img>
          </Col>
          <Col xs={12}>
            <div style={{ marginTop: 10 }}>
              <h3>{post.cliente_nombre}</h3>
              <p>{post.descripcion}</p>
              <p>
                {post.marca_nombre} | {post.vehiculo_nombre}
              </p>

              <span>{post.created_at}</span>
              <div style={{ marginTop: 10 }}>
                {post.estatus == 1 ? (
                  <Tag color="green">Activo</Tag>
                ) : (
                  <Tag
                    color="black"
                    style={{ background: "black", color: "white" }}
                  >
                    Deshabilitado
                  </Tag>
                )}
              </div>

              {post.estatus == 1 ? (
                <div className="input__item" style={{ marginTop: 20 }}>
                  <Button
                    block
                    color="red"
                    appearance="primary"
                    size="lg"
                    onClick={() => _update(0)}
                    style={{ width: 250 }}
                  >
                    Deshabilitar
                  </Button>
                </div>
              ) : (
                <div className="input__item" style={{ marginTop: 20 }}>
                  <Button
                    block
                    appearance="primary"
                    size="lg"
                    onClick={() => _update(1)}
                    style={{ width: 250 }}
                  >
                    Habilitar
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      )}
    </MainLayout>
  );
};

export default PostEditar;
