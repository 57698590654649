import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loader from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Schema } from "rsuite";
import {
  Checkbox,
  CheckboxGroup,
  CheckPicker,
  Row,
  Col,
  Grid,
  Message,
  SelectPicker,
  Input,
  InputNumber,
  Button,
  Notification,
  Alert,
  ButtonToolbar,
  ButtonGroup,
} from "rsuite";

import Search from "../../../components/admin/Search";
import ErrorInput from "../../../components/admin/ErrorInput";
import GaleriaCategoria from "./galeria";
import GaleriaVehiculo from "./galeria";
const API = new APLICLASS();

const VehiculoEditar = () => {
  const navigation = useNavigation();
  const params = navigation._getParam();
  const [loader, setLoader] = useStateIfMounted(false);
  const [noExiste, setNoExiste] = useStateIfMounted(false);
  const [section, setSection] = useStateIfMounted("info");

  const [estatus, setEstatus] = useStateIfMounted("1");
  const [marcas, setMarcas] = useStateIfMounted([]);
  const [anos, setAnos] = useStateIfMounted([]);
  const [ano, setAno] = useStateIfMounted("");

  const { StringType, NumberType, ObjectType, ArrayType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [nombre, setnombre] = useStateIfMounted("");
  const [nombreError, setnombreError] = useStateIfMounted(false);
  const [imagen, setImagen] = useStateIfMounted("");

  const nombreModel = Schema.Model({
    nombre: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatenombre();
  }, [nombre]);
  const _validatenombre = () => {
    let errors = nombreModel.check({
      nombre: nombre,
    });
    if (errors.nombre.hasError) {
      let errorInfo = {
        hasError: errors.nombre.hasError,
        error: errors.nombre.hasError ? errors.nombre.errorMessage : "",
      };
      setnombreError(errorInfo);
    } else {
      setnombreError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [marca, setmarca] = useStateIfMounted("");
  const [marcaError, setmarcaError] = useStateIfMounted(false);
  const marcaModel = Schema.Model({
    marca: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatemarca();
  }, [marca]);
  const _validatemarca = () => {
    let errors = marcaModel.check({
      marca: marca,
    });
    if (errors.marca.hasError) {
      let errorInfo = {
        hasError: errors.marca.hasError,
        error: errors.marca.hasError ? errors.marca.errorMessage : "",
      };
      setmarcaError(errorInfo);
    } else {
      setmarcaError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [plazas, setplazas] = useStateIfMounted("");
  const [plazasError, setplazasError] = useStateIfMounted(false);
  const plazasModel = Schema.Model({
    plazas: NumberType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateplazas();
  }, [plazas]);
  const _validateplazas = () => {
    let errors = plazasModel.check({
      plazas: plazas,
    });
    if (errors.plazas.hasError) {
      let errorInfo = {
        hasError: errors.plazas.hasError,
        error: errors.plazas.hasError ? errors.plazas.errorMessage : "",
      };
      setplazasError(errorInfo);
    } else {
      setplazasError(false);
    }
  };
  // **********************************************************

  const _validate = () => {
    if (
      !nombreError &&
      !marcaError &&
      !plazasError &&
      nombre != "" &&
      marca != "" &&
      plazas != ""
    ) {
      return _save();
    }

    Notification["error"]({
      title: "Error en el formulario",
      description: <span>Verifica la validación del formulario</span>,
    });

    _validatenombre();
    _validatemarca();
    _validateplazas();
  };

  const _save = async () => {
    setLoader(true);

    let request = {
      nombre: nombre,
      estatus: estatus === "" ? null : estatus,
      ano: ano === "" ? null : ano,
      marca: marca,
      plazas: plazas,
      id: params.id,
    };

    const { data } = await API.updateVehiculo(request);

    if (data === true) {
      Alert.success("Registro actualizado exitosamente.");
    } else {
      Alert.error("Hubo un error al actualizar el registro.");
    }

    setLoader(false);
  };

  useEffect(() => {
    _getVehiculo();
    _getMarcas();
    _getAnos();
  }, []);

  const _getVehiculo = async () => {
    const { data } = await API.getVehiculoDetalle(params.id);

    if (data !== false) {
      setnombre(data.nombre);
      setEstatus(data.estatus);
      setImagen(data.imagen);
      setmarca(data.id_marca);
      setplazas(parseInt(data.plazas));
      setAno(parseInt(data.ano));
    } else {
      setNoExiste(true);
    }

    setLoader(false);
  };

  const _delete = async () => {
    const windowAlert = window.confirm(
      "Estás seguro que deseas eliminar este vehículo?"
    );
    if (windowAlert == true) {
      const request = {
        id: params.id,
      };
      const { data } = await API.deleteVehiculo(request);
      if (data === true) {
        Alert.success("Registro eliminado exitosamente.");
        navigation._navigate("/vehiculos");
      } else {
        Alert.error("Ocurrió un error al borrar el registro.");
      }
    }
  };

  const _getMarcas = async () => {
    const { data } = await API.getVehiculosMarcas();
    const dataMarcas = [];
    data.map((item) => {
      let itemParsed = {
        label: item.nombre,
        value: item.id,
      };
      dataMarcas.push(itemParsed);
    });

    setMarcas(dataMarcas);
  };

  const _getAnos = async () => {
    const { data } = await API.getAnosData();
    const dataAnos = [];
    data.map((item) => {
      let itemParsed = {
        label: item,
        value: item,
      };
      dataAnos.push(itemParsed);
    });

    setAnos(dataAnos);
  };

  return (
    <MainLayout>
      <SectionTitle title="Editar vehículo" caption={nombre}></SectionTitle>
      {noExiste ? (
        <Message
          type="error"
          title="Error"
          description={
            <p>
              El vehículo que estás buscando no existe o fue eliminado.
              <br />
              <a href="#" onClick={() => navigation._goBack()}>
                Regresar.
              </a>
            </p>
          }
        />
      ) : (
        <div>
          <div>
            <ButtonToolbar>
              <ButtonGroup block>
                <Button
                  onClick={() => setSection("galeria")}
                  appearance={section === "galeria" ? "primary" : "ghost"}
                  color="orange"
                >
                  Imagen
                </Button>
                <Button
                  onClick={() => setSection("info")}
                  appearance={section === "info" ? "primary" : "ghost"}
                  color="orange"
                >
                  Info
                </Button>
                <Button
                  onClick={() => setSection("estadisticas")}
                  appearance={section === "estadisticas" ? "primary" : "ghost"}
                  color="orange"
                >
                  Estadísticas
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </div>

          {section === "galeria" && (
            <GaleriaVehiculo
              id={params.id}
              imagen={imagen}
              refresh={_getVehiculo}
            ></GaleriaVehiculo>
          )}

          {section === "info" && (
            <div>
              <Row gutter={48}>
                <h3 style={{ marginTop: 20 }}>Info</h3>

                <Col xs={16}>
                  <div className="input__item" style={{ marginTop: 0 }}>
                    <span>Nombre</span>
                    <Input
                      placeholder="Nombre del producto"
                      className={nombreError ? "input-error" : "input-no-error"}
                      value={nombre}
                      onChange={(val) => setnombre(val)}
                    />
                    {nombreError && <ErrorInput text={nombreError.error} />}
                  </div>

                  <div className="input__item">
                    <span>Marca</span>
                    <SelectPicker
                      searchable={false}
                      value={marca}
                      onChange={(val) => setmarca(val)}
                      data={marcas}
                      style={{ width: "100%" }}
                      placeholder="Selecciona la marca"
                      className={marcaError ? "input-error" : "input-no-error"}
                    />
                    {marcaError && <ErrorInput text={marcaError.error} />}
                  </div>

                  <div className="input__item">
                    <span>Año</span>
                    <SelectPicker
                      searchable={false}
                      value={ano}
                      onChange={(val) => setAno(val)}
                      data={anos}
                      style={{ width: "100%" }}
                      placeholder="Selecciona el año"
                    />
                  </div>

                  <div className="input__item">
                    <span>Número de plazas</span>
                    <SelectPicker
                      searchable={false}
                      value={plazas}
                      onChange={(val) => setplazas(val)}
                      data={[
                        {
                          label: "2 plazas",
                          value: 2,
                        },
                        {
                          label: "4 plazas",
                          value: 4,
                        },
                      ]}
                      style={{ width: "100%" }}
                      placeholder="Selecciona las plazas"
                      className={plazasError ? "input-error" : "input-no-error"}
                    />
                    {plazasError && <ErrorInput text={plazasError.error} />}
                  </div>

                  <div className="input__item">
                    <span>Estatus</span>
                    <SelectPicker
                      searchable={false}
                      value={estatus}
                      onChange={(val) => setEstatus(val)}
                      data={[
                        {
                          label: "Activo",
                          value: "1",
                        },
                        {
                          label: "Deshabilitado",
                          value: "0",
                        },
                      ]}
                      style={{ width: "100%" }}
                      placeholder="Selecciona el estatus"
                    />
                  </div>
                </Col>
                <Col xs={8}>
                  <div
                    className="input__item"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      appearance="primary"
                      size="lg"
                      onClick={() => _validate()}
                      block
                    >
                      Editar vehículo
                    </Button>
                  </div>

                  <div
                    className="input__item"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      appearance="ghost"
                      color="red"
                      size="lg"
                      onClick={() => _delete()}
                      block
                    >
                      Eliminar vehículo
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      )}
    </MainLayout>
  );
};

export default VehiculoEditar;
