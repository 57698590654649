import React, { useEffect } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loading from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Badge, Table } from "react-bootstrap";
import { Message, Button, IconButton, Icon, Tag } from "rsuite";
import Search from "../../../components/admin/Search";
import pathImages from "../../../constants/pathImages";
import NoPhoto from "../../../assets/img/no-photos.png";
const API = new APLICLASS();

const CuponesLista = () => {
  const [loader, setLoader] = useStateIfMounted(true);
  const [cupones, setCupones] = useStateIfMounted([]);
  const [emptySearchResult, setEmptySearchResult] = useStateIfMounted(false);
  const [cuponesFiltrados, setCuponesFiltrados] = useStateIfMounted([]);
  const [search, setSearch] = useStateIfMounted("");

  const navigation = useNavigation();

  useEffect(() => {
    _getCupones();
  }, []);

  const _getCupones = async () => {
    const { data } = await API.getCupones();
    setCupones(data);
    setCuponesFiltrados(data);
    setLoader(false);
  };

  const _search = () => {
    setEmptySearchResult(false);
    if (search != "") {
      let resFiltrados = cupones.filter((item) => {
        return item.nombre.toLowerCase().includes(search.toLowerCase());
      });
      if (resFiltrados.length > 0) {
        setCuponesFiltrados(resFiltrados);
      } else {
        setEmptySearchResult(true);
      }
    } else {
      setCuponesFiltrados(cupones);
    }
  };

  useEffect(() => {
    _search();
  }, [search]);

  const _setSearch = (val) => {
    setSearch(val);
  };

  const ListaCupones = () => {
    if (cuponesFiltrados.length > 0) {
      return (
        <div>
          {!emptySearchResult ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Monto del descuento</th>
                  <th>Activo</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cuponesFiltrados.map((item, index) => {
                  return (
                    <tr>
                      <td>{item.id}</td>
                      <td>{item.nombre}</td>

                      <td>
                        <Tag color="red">{item.monto}%</Tag>
                      </td>

                      <td>
                        {item.estatus == 1 ? (
                          <Tag color="green">Activo</Tag>
                        ) : (
                          <Tag
                            color="black"
                            style={{ background: "black", color: "white" }}
                          >
                            Deshabilitado
                          </Tag>
                        )}
                      </td>

                      <td>
                        <IconButton
                          icon={<Icon icon="edit" />}
                          circle
                          size="sm"
                          color="primary"
                          onClick={() =>
                            navigation._replace(`/cupones/${item.id}`)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div>No econtramos registros que coincidan con tu búsqueda</div>
          )}
        </div>
      );
    } else {
      return (
        <Message
          type="info"
          title="Info"
          showIcon
          description={
            <p>
              No has agregado cupones aún.
              <br />
              <a
                style={{ cursor: "pointer" }}
                onClick={() => navigation._navigate("/cupones/agregar")}
              >
                Puedes agregar tu primer cupon aquí.
              </a>
            </p>
          }
        />
      );
    }
  };

  return (
    <MainLayout
      section="Cupones"
      bread={[
        {
          link: "/",
          name: "Inicio",
          active: false,
        },
        {
          link: false,
          name: "Cupones",
          active: true,
        },
      ]}
    >
      <SectionTitle title="Cupones" caption="Lista"></SectionTitle>
      <div className="bar-actions">
        <div className="bar-actions__left">
          <Search value={search} updateInputFunction={_setSearch}></Search>
        </div>
        <div className="bar-actions__right">
          <Button
            appearance="primary"
            onClick={() => navigation._navigate("/cupones/agregar")}
          >
            Agregar nuevo cupón
          </Button>
        </div>
      </div>
      {loader ? (
        <Loading text="Cargando cupones"></Loading>
      ) : (
        <ListaCupones></ListaCupones>
      )}
    </MainLayout>
  );
};

export default CuponesLista;
