import React from "react";

const Empty = (props) => {
  return (
    <div className="empty">
      <span>{props.children}</span>
    </div>
  );
};

export default Empty;
