import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Schema,
  Alert,
  Button,
  Notification,
  IconButton,
  Icon,
} from "rsuite";
import { useStateIfMounted } from "use-state-if-mounted";
import APLICLASS from "../../../api/web";
import Empty from "../../../components/admin/Empty";
import ErrorInput from "../../../components/admin/ErrorInput";
import Loader from "../../../components/admin/Loader";
import useFecha from "../../../hooks/useFecha";
import color from "../../../constants/color";

const API = new APLICLASS();

const VentaMensajes = ({ mensajes, venta, refresh }) => {
  const fecha = useFecha();
  const [mensaje, setMensaje] = useState("");
  const [loaderMensajeForm, setLoaderMensajeForm] = useState(false);

  const _save = async () => {
    setLoaderMensajeForm(true);

    let request = {
      mensaje: mensaje,
      id_venta: venta.venta.id,
      id_cliente: null,
    };

    const { data } = await API.saveMensaje(request);

    if (data.status === true) {
      Alert.success("Registro agregado exitosamente.");
      refresh();
    } else {
      Alert.error("Hubo un error al agregar el registro.");
    }

    setLoaderMensajeForm(false);
  };

  const _deleteMensaje = async (id) => {
    if (window.confirm("Estás seguro que deseas borrar el comentario?")) {
      setLoaderMensajeForm(true);

      let request = {
        id_mensaje: id,
      };

      const { data } = await API.deleteMensaje(request);

      if (data.status === true) {
        Alert.success("Registro eliminado exitosamente.");
        refresh();
      } else {
        Alert.error("Hubo un error al eliminar el registro.");
      }

      setLoaderMensajeForm(false);
    }
  };

  return (
    <div>
      <h3>Mensajes</h3>

      <Row>
        {/* ==== Mensajes ===== */}
        <Col xs={24}>
          <div className="mensajes">
            {loaderMensajeForm ? (
              <Loader></Loader>
            ) : (
              <div>
                {mensajes !== false ? (
                  <div>
                    {Array.isArray(mensajes) && mensajes.length > 0 ? (
                      <div>
                        {mensajes.map((item, index) => {
                          return (
                            <div
                              className="mensaje"
                              key={`mensajes-${index}`}
                              style={{
                                background:
                                  item.id_cliente == null ? "white" : "#f8faff",
                              }}
                            >
                              <div className="mensaje__top">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.id_cliente != null ? (
                                    <h5>{`${item.nombre} ${item.apellidos}`}</h5>
                                  ) : (
                                    <h5
                                      style={{ color: color.accent }}
                                    >{`DMX PERFORMANCE`}</h5>
                                  )}

                                  <span>{fecha.timeAgo(item.created_at)}</span>
                                </div>
                                {item.id_cliente == null && (
                                  <IconButton
                                    round
                                    size="xs"
                                    onClick={() => _deleteMensaje(item.id)}
                                    loading={loaderMensajeForm ? true : false}
                                    icon={<Icon icon="trash" />}
                                    color="red"
                                  />
                                )}
                              </div>
                              <div className="mensaje__texto">
                                <p>{item.mensaje}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <Empty>
                        No pudimos encontrar los mensajes de esta venta
                      </Empty>
                    )}
                  </div>
                ) : (
                  <div>
                    <Empty>No se han agregado mensajes a la venta</Empty>
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>

      {/* ==== Mensajes ===== */}

      <Col xs={24}>
        <div
          className="input__item"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 40,
            marginBottom: 100,
          }}
        >
          <div style={{ flex: 1 }}>
            <Input
              value={mensaje}
              placeholder="Escribe tu mensaje"
              onChange={(val) => setMensaje(val)}
              size="lg"
            />
          </div>

          <div style={{ marginLeft: 20 }}>
            <IconButton
              size="lg"
              onClick={() => _save()}
              loading={loaderMensajeForm ? true : false}
              icon={<Icon icon="send" />}
              color="primary"
            />
          </div>
        </div>
      </Col>
    </div>
  );
};

export default VentaMensajes;
