import moment from "moment";
import "moment/locale/es";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useStateIfMounted } from "use-state-if-mounted";
import APLICLASS from "../../../api/web";
import APIWORLDCLASS from "../../../api/world";
import MainLayout from "../../../components/admin/Layout/main";
import Loading from "../../../components/admin/Loader";
import SectionTitle from "../../../components/admin/sectionTitle";
import useNavigation from "../../../hooks/useNavigation";

const API = new APLICLASS();
const APIWORLD = new APIWORLDCLASS();

const ReporteGeneral = () => {
  const [loader, setLoader] = useStateIfMounted(true);
  const [categoriaFiltro, setCategoriaFiltro] = useStateIfMounted(false);

  const [ventas, setVentas] = useStateIfMounted([]);
  const [emptySearchResult, setEmptySearchResult] = useStateIfMounted(false);

  const navigation = useNavigation();

  useEffect(() => {
    _getReporteProduccion();
  }, []);

  const _getReporteProduccion = async () => {
    setLoader(true);
    const { data } = await API.getReporteProduccion();

    if (Array.isArray(data)) {
      setVentas(data);
    }

    setLoader(false);
  };

  const ReporteProduccion = () => {
    return (
      <div>
        {!emptySearchResult ? (
          <div>
            <Table id="reporteProduccion" striped hover>
              <thead>
                <tr>
                  <th>N. Orden</th>
                  <th>Cliente</th>
                  <th>Fecha</th>
                  <th>Total</th>
                  <th>Shipping</th>
                  <th>Impuestos</th>
                  <th>ZIP Code</th>

                  <th>Tipo de pago</th>

                  <th>Items</th>
                </tr>
              </thead>
              <tbody>
                {ventas.map((venta, index) => {
                  return (
                    <tr>
                      <td>{venta.info.uid}</td>

                      <td>{venta.info.nombre + " " + venta.info.apellidos}</td>

                      <td>{venta.info.created_at}</td>

                      <td>${venta.info.total}</td>

                      <td>${venta.info.shipping_total}</td>

                      <td>${venta.info.impuestos_total}</td>

                      <td>{venta.info.zip}</td>

                      <td>
                        {venta.info.venta_en_pos == 1
                          ? "Venta manual"
                          : "Paypal"}
                      </td>
                      <td>
                        {venta.productos.map((producto, index) => {
                          return (
                            <div
                              style={{
                                marginBottom: 10,
                                borderBottom: "1px solid #ccc",
                              }}
                            >
                              Nombre: {producto.nombre} <br />
                              Cantidad: {producto.cantidad} <br />
                              Color: {producto.color} <br />
                              Precio: {producto.precio} <br />
                              Precio Oferta: {producto.precio_oferta} <br />
                              <br />
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div>No econtramos registros que coincidan con tu búsqueda</div>
        )}
      </div>
    );
  };

  return (
    <MainLayout
      section="Reporte General"
      bread={[
        {
          link: "/",
          name: "Inicio",
          active: false,
        },
        {
          link: false,
          name: "Reportes",
          active: true,
        },
      ]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <SectionTitle
          title="Reporte General"
          caption={"Lista de todas las ventas"}
        ></SectionTitle>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-success"
          table="reporteProduccion"
          filename="Ventas DMXPERFORMANCE"
          sheet="ventas"
          buttonText="Descargar archivo de Excel"
        />
      </div>

      {loader ? (
        <Loading text="Cargando ventas"></Loading>
      ) : (
        <div>
          <ReporteProduccion></ReporteProduccion>
        </div>
      )}
    </MainLayout>
  );
};

export default ReporteGeneral;
