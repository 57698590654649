import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loading from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Badge, Table } from "react-bootstrap";
import { Schema } from "rsuite";

import {
  Message,
  Button,
  IconButton,
  Icon,
  Tag,
  ButtonGroup,
  Dropdown,
  Alert,
  Row,
  Col,
  Input,
  InputPicker,
  SelectPicker,
} from "rsuite";
import Search from "../../../components/admin/Search";
import pathImages from "../../../constants/pathImages";
import NoPhoto from "../../../assets/img/no-photos.png";
import Loader from "../../../components/admin/Loader";
import { useParams } from "react-router-dom";
import VentasInfo from "../../../components/admin/Ventas";
import ErrorInput from "../../../components/admin/ErrorInput";

const API = new APLICLASS();

const DetalleCliente = () => {
  const [loader, setLoader] = useStateIfMounted(true);
  const [cliente, setCliente] = useStateIfMounted(false);
  const [section, setSection] = useStateIfMounted("datos");
  const [ventas, setVentas] = useStateIfMounted([]);
  const [numeroVentas, setNumeroVentas] = useStateIfMounted("");
  const [ventasTotales, setVentasTotales] = useStateIfMounted("");
  const [descuentos, setDescuentos] = useStateIfMounted([]);

  const { id } = useParams();

  const navigation = useNavigation();

  useEffect(() => {
    _getData();
  }, [id]);

  const _getData = async () => {
    setLoader(true);
    await _getCliente();
    await _getClienteCompras();
    setLoader(false);
  };

  const _getCliente = async () => {
    const { data } = await API.getCliente(id);
    if (data !== false) {
      setCliente(data);
      setesRevendedor(data.cliente.seller);
      setmontoDescuento(data.cliente.descuento_seller);
    }
  };

  const _getClienteCompras = async () => {
    const { data } = await API.getClienteReporteDeCompras(id);
    if (data !== false) {
      setVentas(data.Ventas);
      setNumeroVentas(data.Total_Ventas);
      setVentasTotales(data.Monto);
    }
  };

  const _updateRevendedor = async () => {
    const request = {
      id_cliente: id,
      monto: montoDescuento,
      esRevendedor: esRevendedor,
    };
    const { data } = await API.updateRevendedor(request);
    if (data === true) {
      Alert.success("Registro actualizado exitosamente.");
    } else {
      Alert.error("Ocurrió un error al actualizar el registro.");
    }
  };

  const _delete = async () => {
    const windowAlert = window.confirm(
      "Estás seguro que deseas eliminar este cliente?"
    );
    if (windowAlert == true) {
      const request = {
        id: id,
      };
      const { data } = await API.deleteCliente(request);
      if (data === true) {
        Alert.success("Registro eliminado exitosamente.");
        navigation._navigate("/clientes");
      } else {
        Alert.error("Ocurrió un error al borrar el registro.");
      }
    }
  };

  const _bloquear = async () => {
    const windowAlert = window.confirm(
      "Estás seguro que deseas bloquear este cliente?"
    );
    if (windowAlert == true) {
      const request = {
        id: id,
      };
      const { data } = await API.bloquearCliente(request);
      if (data === true) {
        Alert.success("Cliente bloqueado exitosamente.");
        navigation._navigate("/clientes");
      } else {
        Alert.error("Ocurrió un error al bloquear al cliente.");
      }
    }
  };

  const _desbloquear = async () => {
    const windowAlert = window.confirm(
      "Estás seguro que deseas quitar el bloqueo a este cliente?"
    );
    if (windowAlert == true) {
      const request = {
        id: id,
      };
      const { data } = await API.desBloquearCliente(request);
      if (data === true) {
        Alert.success("Cliente desbloqueado exitosamente.");
        navigation._navigate("/clientes");
      } else {
        Alert.error("Ocurrió un error al desbloquear al cliente.");
      }
    }
  };

  const { StringType, NumberType, ObjectType, ArrayType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [montoDescuento, setmontoDescuento] = useStateIfMounted("");
  const [montoDescuentoError, setmontoDescuentoError] =
    useStateIfMounted(false);
  const montoDescuentoModel = Schema.Model({
    montoDescuento: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatemontoDescuento();
  }, [montoDescuento]);
  const _validatemontoDescuento = () => {
    let errors = montoDescuentoModel.check({
      montoDescuento: montoDescuento,
    });
    if (errors.montoDescuento.hasError) {
      let errorInfo = {
        hasError: errors.montoDescuento.hasError,
        error: errors.montoDescuento.hasError
          ? errors.montoDescuento.errorMessage
          : "",
      };
      setmontoDescuentoError(errorInfo);
    } else {
      setmontoDescuentoError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [esRevendedor, setesRevendedor] = useStateIfMounted("");
  const [esRevendedorError, setesRevendedorError] = useStateIfMounted(false);
  const esRevendedorModel = Schema.Model({
    esRevendedor: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateesRevendedor();
  }, [esRevendedor]);
  const _validateesRevendedor = () => {
    let errors = esRevendedorModel.check({
      esRevendedor: esRevendedor,
    });
    if (errors.esRevendedor.hasError) {
      let errorInfo = {
        hasError: errors.esRevendedor.hasError,
        error: errors.esRevendedor.hasError
          ? errors.esRevendedor.errorMessage
          : "",
      };
      setesRevendedorError(errorInfo);
    } else {
      setesRevendedorError(false);
    }
  };
  // **********************************************************

  const _validateDescuento = () => {
    if (
      !montoDescuentoError &&
      !esRevendedorError &&
      montoDescuento != "" &&
      esRevendedor != ""
    ) {
      return _updateRevendedor();
    }

    Notification["error"]({
      title: "Error en el formulario",
      description: <span>Verifica la validación del formulario</span>,
    });

    _validatemontoDescuento();
    _validateesRevendedor();
  };

  useEffect(() => {
    _makeDescuentos();
  }, []);

  const _makeDescuentos = () => {
    let descuentosArr = [];
    for (let i = 0; i < 101; i++) {
      let valueSet = i < 10 ? "0.0" + i : "0." + i;
      let descuentoItem = { label: `${i}%`, value: valueSet };

      descuentosArr.push(descuentoItem);
    }
    setDescuentos(descuentosArr);
  };

  useEffect(() => {
    if (esRevendedor == 0) {
      setmontoDescuento("0.0");
    }
  }, [esRevendedor]);

  return (
    <MainLayout>
      {loader && cliente == false ? (
        <Loader text="cargando datos del cliente..."></Loader>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <SectionTitle
              title={`${cliente?.cliente?.nombre} ${cliente?.cliente?.apellidos}`}
              caption="Perfil del cliente"
            ></SectionTitle>

            {cliente.cliente.estatus == 2 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon icon="minus-circle" style={{ color: "red" }} size="2x" />
                <span style={{ marginLeft: 10 }}>Cliente bloqueado</span>
              </div>
            )}
          </div>

          {loader ? (
            <Loading text="Cargando datos del cliente..."></Loading>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ButtonGroup>
                  <Button
                    onClick={() => setSection("datos")}
                    size="lg"
                    color={section === "datos" ? "blue" : "gray"}
                  >
                    Datos del cliente
                  </Button>
                  <Button
                    onClick={() => setSection("historial")}
                    size="lg"
                    color={section === "historial" ? "blue" : "gray"}
                  >
                    Historial de compras
                  </Button>

                  {/* <Button
                    onClick={() => setSection("seller")}
                    size="lg"
                    color={section === "seller" ? "blue" : "gray"}
                  >
                    Revendedor
                  </Button> */}
                </ButtonGroup>

                <Dropdown appearance="default" title="Opciones para el cliente">
                  <Dropdown.Item
                    onClick={() => navigation._navigate(`/clientes/${id}`)}
                  >
                    Editar
                  </Dropdown.Item>
                  {cliente.cliente.estatus == 2 ? (
                    <Dropdown.Item onClick={() => _desbloquear()}>
                      Quitar bloqueo
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item onClick={() => _bloquear()}>
                      Bloquear
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item onClick={() => _delete()}>
                    Eliminar
                  </Dropdown.Item>
                </Dropdown>
              </div>

              <div style={{ marginTop: 20 }}>
                {section === "datos" && (
                  <div>
                    <h3>Detalles del cliente</h3>

                    <div className="venta__info">
                      {/* item */}
                      <div className="venta__info__item">
                        <div className="venta__info__item__title">
                          <h4>Customer</h4>
                        </div>
                        <div>
                          <Table
                            style={{
                              borderRadius: 20,
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{cliente.cliente.nombre}</td>
                                <td>{cliente.cliente.apellidos}</td>
                                <td>{cliente.cliente.email}</td>
                                <td>{cliente.cliente.telefono}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      {/* /item */}

                      {/* item */}
                      <div className="venta__info__item">
                        <div className="venta__info__item__title">
                          <h4>Shipping</h4>
                        </div>
                        <div>
                          <Table
                            style={{
                              borderRadius: 20,
                            }}
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <tr>
                                <th>Street Adress</th>
                                <th>Apartment, Suite, Unit, etc.</th>
                                <th>Coutry</th>
                                <th>State</th>
                                <th>City</th>
                                <th>Post Code / ZIP</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{cliente.cliente.direccion_calle}</td>
                                <td>{cliente.cliente.direccion_extra}</td>
                                <td>{cliente.cliente.pais}</td>
                                <td>{cliente.cliente.estado}</td>
                                <td>{cliente.cliente.ciudad}</td>
                                <td>{cliente.cliente.zip}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      {/* /item */}
                    </div>
                  </div>
                )}
                {section === "historial" && (
                  <div>
                    <h3>Historial de compras</h3>

                    <VentasInfo
                      mb
                      mt
                      total={ventasTotales}
                      productoMasVendido={false}
                      numeroVentas={numeroVentas}
                      tipoReporte={"Historial"}
                    ></VentasInfo>

                    {ventas.length > 0 ? (
                      <div>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>UID</th>
                              <th>Cliente</th>
                              <th>Fecha</th>
                              <th>Envío</th>
                              <th>Tipo pago</th>
                              <th>Total</th>
                              <th>Estatus</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ventas.map((item, index) => {
                              return (
                                <tr>
                                  <td>{item.uid}</td>
                                  <td>
                                    {item.nombre}
                                    {item.apellidos}
                                  </td>
                                  <td>{item.created_at}</td>
                                  <td>${item.shipping_total}</td>
                                  <td>${item.impuestos_total}</td>
                                  <td>${item.total}</td>

                                  <td>{item.estatus}</td>

                                  <td>
                                    <IconButton
                                      icon={<Icon icon="edit" />}
                                      circle
                                      size="sm"
                                      color="primary"
                                      onClick={() =>
                                        navigation._replace(
                                          `/ventas/${item.id}/info`
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <div>No se han registrado compras</div>
                    )}
                  </div>
                )}

                {section === "seller" && (
                  <div>
                    <h3>Revendedor</h3>
                    esrevendedor? : {esRevendedor}
                    <Row>
                      <Col xs={12}>
                        <div className="input__item">
                          <span>Es revendedor?</span>
                          <SelectPicker
                            searchable={false}
                            value={esRevendedor}
                            onChange={(val) => setesRevendedor(val)}
                            data={[
                              {
                                label: "Si",
                                value: "1",
                              },
                              {
                                label: "No",
                                value: "0",
                              },
                            ]}
                            style={{ width: "100%" }}
                            placeholder="Selecciona si es revendedor"
                            className={
                              esRevendedorError
                                ? "input-error"
                                : "input-no-error"
                            }
                          />
                          {esRevendedorError && (
                            <ErrorInput text={esRevendedorError.error} />
                          )}
                        </div>
                      </Col>

                      <Col xs={12}>
                        <div className="input__item">
                          <span>Descuento</span>
                          <SelectPicker
                            searchable={false}
                            value={montoDescuento}
                            onChange={(val) => setmontoDescuento(val)}
                            data={descuentos}
                            style={{ width: "100%" }}
                            placeholder="Selecciona el monto del descuento"
                            className={
                              montoDescuentoError
                                ? "input-error"
                                : "input-no-error"
                            }
                          />
                          {montoDescuentoError && (
                            <ErrorInput text={montoDescuentoError.error} />
                          )}
                        </div>
                      </Col>

                      <Col xs={24}>
                        <div
                          className="input__item"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div className="input__item">
                            <Button
                              block
                              appearance="primary"
                              onClick={() => _validateDescuento()}
                            >
                              Actualizar
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </MainLayout>
  );
};

export default DetalleCliente;
