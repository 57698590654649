import React from "react";

const VentasInfo = (props) => {
  return (
    <div>
      <div
        className="dashboard__totales dashboard-box"
        style={{
          marginTop: props.mt ? 20 : 0,
          marginBottom: props.mb ? 20 : 0,
        }}
      >
        <div className="dashboard__totales__top">
          <div className="dashboard__totales__top__item">
            <h5>Ventas netas</h5>
            <h1>${props.total}</h1>
          </div>
          <div className="dashboard__totales__top__item">
            <h5>Número de ventas</h5>
            <h1>{props.numeroVentas}</h1>
          </div>

          <div className="dashboard__totales__top__item">
            <h5>Tipo de reporte</h5>
            <h1>{props.tipoReporte}</h1>
          </div>
        </div>

        {props.productoMasVendido != false && (
          <div className="dashboard__totales__info">
            <div
              className="dashboard__totales__info__item"
              style={{ textAlign: "left" }}
            >
              <span>Producto mas vendido</span>
              <h6>{props.productoMasVendido.nombre}</h6>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VentasInfo;
