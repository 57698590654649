import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Schema } from "rsuite";
import {
  Checkbox,
  CheckboxGroup,
  CheckPicker,
  Row,
  Col,
  Grid,
  Message,
  SelectPicker,
  Input,
  InputNumber,
  Button,
  Notification,
  Alert,
} from "rsuite";
import Loader from "../../../components/admin/Loader";

import Search from "../../../components/admin/Search";
import ErrorInput from "../../../components/admin/ErrorInput";
const API = new APLICLASS();

const VentaEstatus = (props) => {
  const navigation = useNavigation();
  const [loader, setLoader] = useStateIfMounted(false);

  const { StringType, NumberType, ObjectType, ArrayType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [estatus, setestatus] = useStateIfMounted(props.venta.venta?.status);
  const [estatusError, setestatusError] = useStateIfMounted(false);
  const estatusModel = Schema.Model({
    estatus: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateestatus();
  }, [estatus]);
  const _validateestatus = () => {
    let errors = estatusModel.check({
      estatus: estatus,
    });
    if (errors.estatus.hasError) {
      let errorInfo = {
        hasError: errors.estatus.hasError,
        error: errors.estatus.hasError ? errors.estatus.errorMessage : "",
      };
      setestatusError(errorInfo);
    } else {
      setestatusError(false);
    }
  };
  // **********************************************************
  const _validate = () => {
    if (!estatusError && estatus != "") {
      return _updateEstatus();
    }

    Notification["error"]({
      title: "Error en el formulario",
      description: <span>Verifica la validación del formulario</span>,
    });

    _validateestatus();
  };

  const _updateEstatus = async () => {
    setLoader(true);

    let request = {
      estatus: estatus,
      id_venta: props.venta.venta.id,
    };

    const { data } = await API.updateEstatusVenta(request);

    if (data.status === true) {
      Alert.success("Registro actualizado exitosamente.");
    } else {
      Alert.error("Hubo un error al actualizar el registro.");
    }

    setLoader(false);
  };

  return (
    <div>
      <Row>
        <Col xs={24}>
          <div className="input__item">
            <span>Estatus</span>
            <SelectPicker
              searchable={false}
              value={estatus}
              onChange={(val) => setestatus(val)}
              data={[
                {
                  label: "Processing",
                  value: "Processing",
                },
                {
                  label: "Confirmed",
                  value: "Confirmed",
                },
                {
                  label: "Sent",
                  value: "Sent",
                },

                {
                  label: "Cancelled",
                  value: "Cancelled",
                },
              ]}
              style={{ width: "100%" }}
              placeholder="Selecciona el estatus"
              className={estatusError ? "input-error" : "input-no-error"}
            />
            {estatusError && <ErrorInput text={estatusError.error} />}
          </div>
        </Col>

        <Col xs={24}>
          <div
            className="input__item"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="input__item">
              <Button
                block
                appearance="primary"
                onClick={() => _validate()}
                loading={loader}
              >
                Actualizar Estatus
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VentaEstatus;
