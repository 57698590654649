import React, { useEffect, useRef } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { Table } from "react-bootstrap";
import { ButtonGroup, Tag, Button } from "rsuite";
import APLICLASS from "../../../api/web";
import { useReactToPrint } from "react-to-print";

const API = new APLICLASS();

const VentaInfo = ({ venta }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      {venta != false && (
        <div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button className="btn btn-success" onClick={handlePrint}>
              Imprimir
            </button>
          </div>

          <div className="venta" ref={componentRef} style={{ paddingTop: 20 }}>
            <div className="venta__header">
              <h3>#{venta.venta.uid}</h3>
              <Tag color={venta.venta.status ? "orange" : "green"}>
                {venta.venta.status}
              </Tag>
            </div>

            <div className="venta__info">
              {/* item */}
              <div className="venta__info__item">
                <div className="venta__info__item__title">
                  <h4>Customer</h4>
                </div>
                <div>
                  <Table
                    style={{
                      borderRadius: 20,
                    }}
                    striped
                    bordered
                    hover
                  >
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{venta.venta.nombre}</td>
                        <td>{venta.venta.apellidos}</td>
                        <td>{venta.venta.email}</td>
                        <td>{venta.venta.telefono}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* /item */}

              {/* item */}
              <div className="venta__info__item">
                <div className="venta__info__item__title">
                  <h4>Shipping</h4>
                </div>
                <div>
                  <Table
                    style={{
                      borderRadius: 20,
                    }}
                    striped
                    bordered
                    hover
                  >
                    <thead>
                      <tr>
                        <th>Street Adress</th>
                        <th>Apartment, Suite, Unit, etc.</th>
                        <th>Coutry</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Post Code / ZIP</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{venta.venta.direccion_calle}</td>
                        <td>{venta.venta.direccion_extra}</td>
                        <td>{venta.venta.pais}</td>
                        <td>{venta.venta.estado}</td>
                        <td>{venta.venta.ciudad}</td>
                        <td>{venta.venta.zip}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* /item */}

              {/* item */}
              <div className="venta__info__item">
                <div className="venta__info__item__title">
                  <h4>Productos</h4>
                  {venta.productos !== false &&
                  Array.isArray(venta.productos) &&
                  venta.productos.length > 0 ? (
                    <div>
                      <Table
                        style={{
                          borderRadius: 20,
                        }}
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Price offer</th>
                            <th>Quantity</th>
                            <th>Color</th>
                            <th>Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {venta.productos.map((item, index) => {
                            return (
                              <tr>
                                <td>{item.nombre}</td>
                                <td>${item.precio}</td>
                                <td>
                                  {item.precio_oferta === null
                                    ? ""
                                    : `$${item.precio_oferta}`}
                                </td>
                                <td>{item.cantidad}</td>
                                <td>{item.color}</td>
                                <td>{item.comments}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div>No encontramos productos en esta venta</div>
                  )}
                </div>
              </div>
              {/* /item */}

              {/* item */}
              {venta.cupon != false && (
                <div className="venta__info__item">
                  <div className="venta__info__item__title">
                    <h4>Cupón</h4>

                    <div>
                      <Table
                        style={{
                          borderRadius: 20,
                        }}
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Porcentaje de descuento</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{venta.cupon.nombre}</td>
                            <td>-{venta.cupon.monto * 100}%</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              )}
              {/* /item */}

              {/* item */}
              {venta.cupon != false && (
                <div className="venta__info__item">
                  <div className="venta__info__item__title">
                    <h4>Cupón</h4>

                    <div>
                      <Table
                        style={{
                          borderRadius: 20,
                        }}
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <tr>
                            <th>Nombre</th>
                            <th>Porcentaje de descuento</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{venta.cupon.nombre}</td>
                            <td>-{venta.cupon.monto * 100}%</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              )}
              {/* /item */}

              {/* item */}
              <div className="venta__info__item">
                <div className="venta__info__item__title">
                  <h4>Descuento manual</h4>

                  <div>
                    <Table
                      style={{
                        borderRadius: 20,
                      }}
                      striped
                      bordered
                      hover
                    >
                      <thead>
                        <tr>
                          <th>Monto</th>
                          <th>Porcentaje de descuento</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            $
                            {venta.venta.descuento_total == null
                              ? 0
                              : venta.venta.descuento_total}
                          </td>

                          <td>{venta.venta.descuento * 100}%</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              {/* /item */}

              {/* item */}
              {venta.pago.tipo == "Online" && (
                <div className="venta__info__item">
                  <div className="venta__info__item__title">
                    <h4>Paypal</h4>

                    <div>
                      <Table
                        style={{
                          borderRadius: 20,
                        }}
                        striped
                        bordered
                        hover
                      >
                        <thead>
                          <tr>
                            <th>id_paypal_transaction</th>
                            <th>payer_first_name</th>
                            <th>payer_last_name</th>
                            <th>payer_email</th>
                            <th>payer_phone</th>
                            <th>payer_id</th>
                            <th>status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{venta.pago.id_paypal_transaction}</td>
                            <td>{venta.pago.payer_first_name}</td>
                            <td>{venta.pago.payer_last_name}</td>
                            <td>{venta.pago.payer_email}</td>
                            <td>{venta.pago.payer_phone}</td>
                            <td>{venta.pago.payer_id}</td>
                            <td>{venta.pago.status}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              )}

              {/* /item */}

              {/* TOTALS */}
              <div className="venta__totals">
                <div className="venta__totals__item">
                  <div className="venta__totals__item__caption">Sub Total</div>
                  <div className="venta__totals__item__text">
                    ${venta.venta.subtotal}
                  </div>
                </div>

                {venta.cupon != false && (
                  <div className="venta__totals__item">
                    <div className="venta__totals__item__caption">
                      Cupón descuento
                    </div>
                    <div
                      className="venta__totals__item__text"
                      style={{
                        color:
                          venta.venta.cupon_total !== null ? "red" : "#575757",
                      }}
                    >
                      -$
                      {venta.venta.cupon_total !== null
                        ? venta.venta.cupon_total
                        : 0}
                    </div>
                  </div>
                )}

                <div className="venta__totals__item">
                  <div className="venta__totals__item__caption">
                    Descuento manual
                  </div>
                  <div
                    className="venta__totals__item__text"
                    style={{
                      color:
                        venta.venta.descuento_total != "0.00"
                          ? "red"
                          : "#575757",
                    }}
                  >
                    $
                    {venta.venta.descuento_total != "0.0"
                      ? venta.venta.descuento_total
                      : 0}
                  </div>
                </div>

                <div className="venta__totals__item">
                  <div className="venta__totals__item__caption">Shipping</div>
                  <div className="venta__totals__item__text">
                    ${venta.venta.shipping_total}
                  </div>
                </div>

                <div className="venta__totals__item">
                  <div className="venta__totals__item__caption">TAX</div>
                  <div className="venta__totals__item__text">
                    ${venta.venta.impuestos_total}
                  </div>
                </div>
                <div className="venta__totals__item">
                  <div className="venta__totals__item__caption">TOTAL</div>
                  <div className="venta__totals__item__text venta__totals__item__grand-total">
                    ${venta.venta.total}
                  </div>
                </div>
              </div>
              {/* /TOTALS */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VentaInfo;
