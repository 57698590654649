import React, { useEffect } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loading from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Badge, Table } from "react-bootstrap";
import { Message, Button, IconButton, Icon, Tag } from "rsuite";
import Search from "../../../components/admin/Search";
import pathImages from "../../../constants/pathImages";
import NoPhoto from "../../../assets/img/no-photos.png";
import Polaris2P from "../../../assets/img/polaris-2-black.png";
import Polaris4P from "../../../assets/img/polaris-4-black.png";
import Canam2P from "../../../assets/img/canam-2-black.png";
import Canam4P from "../../../assets/img/canam-4-black.png";

const API = new APLICLASS();

const VehiculosLista = () => {
  const [loader, setLoader] = useStateIfMounted(true);
  const [vehiculos, setVehiculos] = useStateIfMounted([]);
  const [emptySearchResult, setEmptySearchResult] = useStateIfMounted(false);
  const [vehiculosFiltrados, setVehiculosFiltrados] = useStateIfMounted([]);
  const [search, setSearch] = useStateIfMounted("");

  const navigation = useNavigation();

  useEffect(() => {
    _getVehiculos();
  }, []);

  const _getVehiculos = async () => {
    const { data } = await API.getVehiculos();
    setVehiculos(data);
    setVehiculosFiltrados(data);
    setLoader(false);
  };

  const _search = () => {
    setEmptySearchResult(false);
    if (search != "") {
      let resFiltrados = vehiculos.filter((item) => {
        return item.nombre.toLowerCase().includes(search.toLowerCase());
      });
      if (resFiltrados.length > 0) {
        setVehiculosFiltrados(resFiltrados);
      } else {
        setEmptySearchResult(true);
      }
    } else {
      setVehiculosFiltrados(vehiculos);
    }
  };

  useEffect(() => {
    _search();
  }, [search]);

  const _setSearch = (val) => {
    setSearch(val);
  };

  const ListaVehiculos = () => {
    if (vehiculosFiltrados.length > 0) {
      return (
        <div>
          {!emptySearchResult ? (
            <Table striped hover>
              <thead>
                <tr>
                  <th>Imagen</th>
                  <th>Nombre</th>
                  <th>Plazas</th>
                  <th>Marca</th>
                  <th>Slug</th>
                  <th>Estatus</th>

                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {vehiculosFiltrados.map((item, index) => {
                  return (
                    <tr>
                      <td className="td--has-img">
                        {item.id_categoria === "1" ? (
                          <img
                            src={item.plazas === "2" ? Canam2P : Canam4P}
                          ></img>
                        ) : (
                          <img
                            src={item.plazas === "4" ? Canam2P : Canam4P}
                          ></img>
                        )}
                      </td>

                      <td style={{ paddingTop: 25 }}>{item.nombre}</td>
                      <td style={{ paddingTop: 25 }}>{item.plazas}</td>
                      <td style={{ paddingTop: 25 }}>{item.marca}</td>

                      <td style={{ paddingTop: 25 }}>{item.slug}</td>
                      <td style={{ paddingTop: 25 }}>
                        <Tag color={item.estatus == 1 ? "cyan" : "danger"}>
                          {item.estatus == 1 ? "Activo" : "No Activo"}
                        </Tag>
                      </td>

                      <td style={{ paddingTop: 20 }}>
                        <IconButton
                          icon={<Icon icon="edit" />}
                          circle
                          size="sm"
                          color="primary"
                          onClick={() =>
                            navigation._replace(`/vehiculos/${item.id}`)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div>No econtramos registros que coincidan con tu búsqueda</div>
          )}
        </div>
      );
    } else {
      return (
        <Message
          type="info"
          title="Info"
          showIcon
          description={
            <p>
              No has agregado vehículos aún.
              <br />
              <a
                style={{ cursor: "pointer" }}
                onClick={() => navigation._navigate("/vehiculos/agregar")}
              >
                Puedes agregar tu primer vehículo aquí.
              </a>
            </p>
          }
        />
      );
    }
  };

  return (
    <MainLayout>
      <SectionTitle title="Vehículos" caption="Lista"></SectionTitle>
      <div className="bar-actions">
        <div className="bar-actions__left">
          <Search value={search} updateInputFunction={_setSearch}></Search>
        </div>
        <div className="bar-actions__right">
          <Button
            appearance="primary"
            onClick={() => navigation._navigate("/vehiculos/agregar")}
          >
            Agregar nuevo vehículo
          </Button>
        </div>
      </div>
      {loader ? (
        <Loading text="Cargando vehículos"></Loading>
      ) : (
        <ListaVehiculos></ListaVehiculos>
      )}
    </MainLayout>
  );
};

export default VehiculosLista;
