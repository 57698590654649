import React from "react";
import { Col, Container, Grid, Row } from "rsuite";
import MenuTop from "../TopMenu";

const MainLayout = (props) => {
  return (
    <div className="main">
      {props.noHeader ? null : (
        <MenuTop section={props.section} bread={props.bread}></MenuTop>
      )}

      <Grid style={{ paddingTop: props.noHeader ? 150 : 0 }}>
        <Container>
          <Row>
            <Col xs={24}>
              <div className="main-content">{props.children}</div>
            </Col>
          </Row>
        </Container>
      </Grid>
    </div>
  );
};

export default MainLayout;
