import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loading from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Schema } from "rsuite";
import {
  Checkbox,
  CheckboxGroup,
  CheckPicker,
  Row,
  Col,
  Grid,
  Message,
  SelectPicker,
  Input,
  InputNumber,
  Button,
  Notification,
  Alert,
  ButtonToolbar,
  ButtonGroup,
} from "rsuite";

import Search from "../../../components/admin/Search";
import ErrorInput from "../../../components/admin/ErrorInput";
import GaleriaProducto from "./galeria";
const API = new APLICLASS();

const ProductoEditar = () => {
  const navigation = useNavigation();
  const params = navigation._getParam();
  const [loader, setLoader] = useStateIfMounted(true);
  const [loaderCategorias, setLoaderCategorias] = useStateIfMounted(true);
  const [loaderVehiculos, setLoaderVehiculos] = useStateIfMounted(true);
  const [noExiste, setNoExiste] = useStateIfMounted(false);
  const [section, setSection] = useStateIfMounted("info");

  const [categorias, setCategorias] = useStateIfMounted([]);
  const [vehiculos, setVehiculos] = useStateIfMounted([]);
  const [estatus, setEstatus] = useStateIfMounted(1);
  const [producto, setProducto] = useStateIfMounted("");
  const [descripcion, setDescripcion] = useStateIfMounted("");

  const [vehiculosSeleccionados, setvehiculosSeleccionados] =
    useStateIfMounted("");

  const { StringType, NumberType, ObjectType, ArrayType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [nombre, setnombre] = useStateIfMounted("");
  const [nombreError, setnombreError] = useStateIfMounted(false);
  const nombreModel = Schema.Model({
    nombre: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatenombre();
  }, [nombre]);
  const _validatenombre = () => {
    let errors = nombreModel.check({
      nombre: nombre,
    });
    if (errors.nombre.hasError) {
      let errorInfo = {
        hasError: errors.nombre.hasError,
        error: errors.nombre.hasError ? errors.nombre.errorMessage : "",
      };
      setnombreError(errorInfo);
    } else {
      setnombreError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [precio, setprecio] = useStateIfMounted("");
  const [precioError, setprecioError] = useStateIfMounted(false);
  const precioModel = Schema.Model({
    precio: NumberType("Ingresa un número válido").isRequired(
      "Este campo es requerido"
    ),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateprecio();
  }, [precio]);
  const _validateprecio = () => {
    let errors = precioModel.check({
      precio: precio,
    });
    if (errors.precio.hasError) {
      let errorInfo = {
        hasError: errors.precio.hasError,
        error: errors.precio.hasError ? errors.precio.errorMessage : "",
      };
      setprecioError(errorInfo);
    } else {
      setprecioError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [precioOferta, setprecioOferta] = useStateIfMounted("");
  const [precioOfertaError, setprecioOfertaError] = useStateIfMounted(false);
  const precioOfertaModel = Schema.Model({
    precioOferta: NumberType(),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateprecioOferta();
  }, [precioOferta]);
  const _validateprecioOferta = () => {
    let errors = precioOfertaModel.check({
      precioOferta: precioOferta,
    });
    if (errors.precioOferta.hasError) {
      let errorInfo = {
        hasError: errors.precioOferta.hasError,
        error: errors.precioOferta.hasError
          ? errors.precioOferta.errorMessage
          : "",
      };
      setprecioOfertaError(errorInfo);
    } else {
      setprecioOfertaError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [peso, setpeso] = useStateIfMounted("");
  const [pesoError, setpesoError] = useStateIfMounted(false);
  const pesoModel = Schema.Model({
    peso: NumberType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatepeso();
  }, [peso]);
  const _validatepeso = () => {
    let errors = pesoModel.check({
      peso: peso,
    });
    if (errors.peso.hasError) {
      let errorInfo = {
        hasError: errors.peso.hasError,
        error: errors.peso.hasError ? errors.peso.errorMessage : "",
      };
      setpesoError(errorInfo);
    } else {
      setpesoError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [largo, setlargo] = useStateIfMounted("");
  const [largoError, setlargoError] = useStateIfMounted(false);
  const largoModel = Schema.Model({
    largo: NumberType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatelargo();
  }, [largo]);
  const _validatelargo = () => {
    let errors = largoModel.check({
      largo: largo,
    });
    if (errors.largo.hasError) {
      let errorInfo = {
        hasError: errors.largo.hasError,
        error: errors.largo.hasError ? errors.largo.errorMessage : "",
      };
      setlargoError(errorInfo);
    } else {
      setlargoError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [ancho, setancho] = useStateIfMounted("");
  const [anchoError, setanchoError] = useStateIfMounted(false);
  const anchoModel = Schema.Model({
    ancho: NumberType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateancho();
  }, [ancho]);
  const _validateancho = () => {
    let errors = anchoModel.check({
      ancho: ancho,
    });
    if (errors.ancho.hasError) {
      let errorInfo = {
        hasError: errors.ancho.hasError,
        error: errors.ancho.hasError ? errors.ancho.errorMessage : "",
      };
      setanchoError(errorInfo);
    } else {
      setanchoError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [alto, setalto] = useStateIfMounted("");
  const [altoError, setaltoError] = useStateIfMounted(false);
  const altoModel = Schema.Model({
    alto: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatealto();
  }, [alto]);
  const _validatealto = () => {
    let errors = altoModel.check({
      alto: alto,
    });
    if (errors.alto.hasError) {
      let errorInfo = {
        hasError: errors.alto.hasError,
        error: errors.alto.hasError ? errors.alto.errorMessage : "",
      };
      setaltoError(errorInfo);
    } else {
      setaltoError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [stock, setstock] = useStateIfMounted("");
  const [stockError, setstockError] = useStateIfMounted(false);
  const stockModel = Schema.Model({
    stock: NumberType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatestock();
  }, [stock]);
  const _validatestock = () => {
    let errors = stockModel.check({
      stock: stock,
    });
    if (errors.stock.hasError) {
      let errorInfo = {
        hasError: errors.stock.hasError,
        error: errors.stock.hasError ? errors.stock.errorMessage : "",
      };
      setstockError(errorInfo);
    } else {
      setstockError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [categoriaSelccionada, setcategoriaSelccionada] = useStateIfMounted("");
  const [categoriaSelccionadaError, setcategoriaSelccionadaError] =
    useStateIfMounted(false);
  const categoriaSelccionadaModel = Schema.Model({
    categoriaSelccionada: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatecategoriaSelccionada();
  }, [categoriaSelccionada]);
  const _validatecategoriaSelccionada = () => {
    let errors = categoriaSelccionadaModel.check({
      categoriaSelccionada: categoriaSelccionada,
    });
    if (errors.categoriaSelccionada.hasError) {
      let errorInfo = {
        hasError: errors.categoriaSelccionada.hasError,
        error: errors.categoriaSelccionada.hasError
          ? errors.categoriaSelccionada.errorMessage
          : "",
      };
      setcategoriaSelccionadaError(errorInfo);
    } else {
      setcategoriaSelccionadaError(false);
    }
  };
  // **********************************************************

  useEffect(() => {
    _getCategorias();
    _getVehiculos();
    _getProducto();
  }, []);

  const _getCategorias = async () => {
    const { data } = await API.getCategorias();

    if (data.length > 0) {
      const dataCategorias = [];
      data.map((item) => {
        let itemParsed = {
          label: item.nombre,
          value: item.id,
        };
        dataCategorias.push(itemParsed);
      });
      setCategorias(dataCategorias);
    }
    setLoaderCategorias(false);
  };

  const _getVehiculos = async () => {
    const { data } = await API.getVehiculos();

    if (data.length > 0) {
      const dataVehiculos = [];
      data.map((item) => {
        let itemParsed = {
          label: item.nombre,
          value: item.id,
        };
        dataVehiculos.push(itemParsed);
      });

      setVehiculos(dataVehiculos);
    }
    setLoaderVehiculos(false);
  };

  const _getProducto = async () => {
    const { data } = await API.getProductoDetalle(params.id);

    if (data.producto !== false) {
      setnombre(data.producto.nombre);
      setprecio(data.producto.precio);
      setprecioOferta(data.producto.precio_oferta);
      setpeso(data.producto.peso);
      setlargo(data.producto.largo);
      setancho(data.producto.ancho);
      setalto(data.producto.alto);
      setstock(data.producto.stock);
      setEstatus(data.producto.estatus);
      setcategoriaSelccionada(data.producto.id_categoria);
      setDescripcion(data.producto.descripcion);

      //seteamos los vehiculos
      if (data.vehiculos !== false) {
        if (Array.isArray(data.vehiculos)) {
          const vehiculosData = [];
          data.vehiculos.map((item) => {
            vehiculosData.push(item.id);
          });
          setvehiculosSeleccionados(vehiculosData);
        }
      }
    } else {
      setNoExiste(true);
    }

    setLoader(false);
  };

  const _validate = () => {
    if (
      !nombreError &&
      !precioError &&
      !precioOfertaError &&
      !pesoError &&
      !largoError &&
      !altoError &&
      !anchoError &&
      !stockError &&
      !categoriaSelccionadaError &&
      nombre != "" &&
      precio != "" &&
      peso != "" &&
      largo != "" &&
      alto != "" &&
      ancho != "" &&
      stock != "" &&
      categoriaSelccionada != ""
    ) {
      return _save();
    }

    Notification["error"]({
      title: "Error en el formulario",
      description: <span>Verifica la validación del formulario</span>,
    });

    _validatenombre();
    _validateprecio();
    _validateprecioOferta();
    _validatepeso();
    _validatelargo();
    _validatealto();
    _validateancho();
    _validatestock();
    _validatecategoriaSelccionada();
  };

  const _save = async () => {
    setLoader(true);

    let request = {
      nombre: nombre,
      precio: precio,
      precio_oferta: precioOferta !== "" ? precioOferta : null,
      peso: peso,
      largo: largo,
      alto: alto,
      ancho: ancho,
      stock: stock,
      categoria: categoriaSelccionada,
      vehiculos:
        vehiculosSeleccionados.length > 0 ? vehiculosSeleccionados : false,
      estatus: estatus,
      id: params.id,
      descripcion: descripcion,
    };

    const { data } = await API.updateProducto(request);

    if (data.status === true) {
      Notification["success"]({
        title: "Hecho!",
        description: <span>El registro se guardó exitosamente</span>,
      });
      navigation._navigate("/productos");
    } else {
      Notification["error"]({
        title: "Error!",
        description: (
          <span>Ocurrió un error desconocido, por favor intenta mas tarde</span>
        ),
      });
    }

    setLoader(false);
  };

  const _delete = async () => {
    const windowAlert = window.confirm(
      "Estás seguro que deseas eliminar este producto?"
    );
    if (windowAlert == true) {
      const request = {
        id_producto: params.id,
      };
      const { data } = await API.deleteProducto(request);
      if (data.status === true) {
        Alert.success("Registro eliminado exitosamente.");
        navigation._navigate("/productos");
      } else {
        Alert.error("Ocurrió un error al borrar el registro.");
      }
    }
  };

  return (
    <MainLayout backLink="/">
      <SectionTitle title={"Editar"} caption={nombre}></SectionTitle>

      {noExiste ? (
        <Message
          type="error"
          title="Error"
          description={
            <p>
              El producto que estás buscando no existe o fue eliminado.
              <br />
              <a href="#" onClick={() => navigation._goBack()}>
                Regresar.
              </a>
            </p>
          }
        />
      ) : (
        <div>
          <div>
            <ButtonToolbar>
              <ButtonGroup block>
                <Button
                  onClick={() => setSection("galeria")}
                  appearance={section === "galeria" ? "primary" : "ghost"}
                  color="orange"
                >
                  Galería
                </Button>
                <Button
                  onClick={() => setSection("info")}
                  appearance={section === "info" ? "primary" : "ghost"}
                  color="orange"
                >
                  Info
                </Button>
                <Button
                  onClick={() => setSection("estadisticas")}
                  appearance={section === "estadisticas" ? "primary" : "ghost"}
                  color="orange"
                >
                  Estadísticas
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </div>
          {section === "galeria" && (
            <GaleriaProducto id={params.id}></GaleriaProducto>
          )}
          {section === "info" && (
            <Row gutter={48}>
              <h3 style={{ marginTop: 20 }}>Info</h3>
              <Col xs={16}>
                {!loader ? (
                  <div>
                    <div className="input__item" style={{ marginTop: 0 }}>
                      <span>Nombre</span>
                      <Input
                        placeholder="Nombre del producto"
                        className={
                          nombreError ? "input-error" : "input-no-error"
                        }
                        value={nombre}
                        onChange={(val) => setnombre(val)}
                      />
                      {nombreError && <ErrorInput text={nombreError.error} />}
                    </div>

                    <Row>
                      <Col xs={12}>
                        <div className="input__item">
                          <span>Precio</span>
                          <InputNumber
                            className={
                              precioError ? "input-error" : "input-no-error"
                            }
                            value={precio}
                            onChange={(val) => setprecio(val)}
                            placeholder="Precio del producto"
                          />
                          {precioError && (
                            <ErrorInput text={precioError.error} />
                          )}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="input__item">
                          <span>Precio de oferta</span>
                          <InputNumber
                            className={
                              precioOfertaError
                                ? "input-error"
                                : "input-no-error"
                            }
                            value={precioOferta}
                            onChange={(val) => setprecioOferta(val)}
                            placeholder="Precio de oferta del producto"
                          />
                          {precioOfertaError && (
                            <ErrorInput text={precioOfertaError.error} />
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <div className="input__item">
                          <span>Peso</span>
                          <InputNumber
                            placeholder="Peso del producto"
                            className={
                              pesoError ? "input-error" : "input-no-error"
                            }
                            value={peso}
                            onChange={(val) => setpeso(val)}
                          />
                          {pesoError && <ErrorInput text={pesoError.error} />}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="input__item">
                          <span>Largo</span>
                          <InputNumber
                            placeholder="Largo del producto"
                            className={
                              largoError ? "input-error" : "input-no-error"
                            }
                            value={largo}
                            onChange={(val) => setlargo(val)}
                          />
                          {largoError && <ErrorInput text={largoError.error} />}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="input__item">
                          <span>Ancho</span>
                          <InputNumber
                            className={
                              anchoError ? "input-error" : "input-no-error"
                            }
                            value={ancho}
                            onChange={(val) => setancho(val)}
                            placeholder="Ancho del producto"
                          />
                          {anchoError && <ErrorInput text={anchoError.error} />}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="input__item">
                          <span>Alto</span>
                          <InputNumber
                            placeholder="Alto del producto"
                            className={
                              altoError ? "input-error" : "input-no-error"
                            }
                            value={alto}
                            onChange={(val) => setalto(val)}
                          />
                          {altoError && <ErrorInput text={altoError.error} />}
                        </div>
                      </Col>
                    </Row>

                    <div className="input__item">
                      <span>Stock</span>
                      <InputNumber
                        placeholder="Cantidad de stock del producto"
                        className={
                          stockError ? "input-error" : "input-no-error"
                        }
                        value={stock}
                        onChange={(val) => setstock(val)}
                      />
                      {stockError && <ErrorInput text={stockError.error} />}
                    </div>

                    <div className="input__item">
                      <span>Estatus</span>
                      <SelectPicker
                        searchable={false}
                        value={estatus}
                        onChange={(val) => setEstatus(val)}
                        data={[
                          {
                            label: "Activo",
                            value: "1",
                          },
                          {
                            label: "Deshabilitado",
                            value: "0",
                          },
                        ]}
                        style={{ width: "100%" }}
                        placeholder="Selecciona el estatus"
                      />
                    </div>

                    <div className="input__item">
                      <span>Descripción</span>
                      <Input
                        value={descripcion}
                        onChange={(val) => setDescripcion(val)}
                        rows={5}
                        name="textarea"
                        componentClass="textarea"
                      />
                    </div>
                  </div>
                ) : (
                  <Loading text="Cargando datos del producto"></Loading>
                )}
              </Col>
              <Col xs={8}>
                <div>
                  {loaderCategorias ? (
                    <Loading></Loading>
                  ) : (
                    <div>
                      {categorias.length > 0 ? (
                        <div className="input__item" style={{ marginTop: 0 }}>
                          <span>Categoría</span>
                          <SelectPicker
                            searchable={true}
                            data={categorias}
                            style={{ width: "100%" }}
                            placeholder="Selecciona la categoría"
                            className={
                              categoriaSelccionadaError
                                ? "input-error"
                                : "input-no-error"
                            }
                            value={categoriaSelccionada}
                            onChange={(val) => setcategoriaSelccionada(val)}
                          />
                          {categoriaSelccionadaError && (
                            <ErrorInput
                              text={categoriaSelccionadaError.error}
                            />
                          )}
                        </div>
                      ) : (
                        <Message
                          showIcon
                          type="error"
                          description="No has agregado categorías, no podrás editar un producto hasta que existan categorías disponibles"
                        />
                      )}
                    </div>
                  )}
                </div>

                <div className="input__item">
                  {loaderVehiculos ? (
                    <Loading></Loading>
                  ) : (
                    <div>
                      {vehiculos.length > 0 ? (
                        <div>
                          <span>Vehículos compatibles</span>
                          <CheckPicker
                            data={vehiculos}
                            placeholder="Selecciona los vehículos"
                            value={vehiculosSeleccionados}
                            onChange={(val) => setvehiculosSeleccionados(val)}
                            style={{ width: "100%" }}
                          />
                        </div>
                      ) : (
                        <Message
                          showIcon
                          type="error"
                          description="No has agregado vehículos, no podrás editar productos hasta existan vehículos disponibles"
                        />
                      )}
                    </div>
                  )}
                </div>

                <div
                  className="input__item"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    block
                    appearance="primary"
                    size="lg"
                    onClick={() => _validate()}
                  >
                    Editar Producto
                  </Button>
                </div>

                <div
                  className="input__item"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    color="red"
                    appearance="ghost"
                    block
                    size="lg"
                    onClick={() => _delete()}
                  >
                    Eliminar Producto
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}
    </MainLayout>
  );
};

export default ProductoEditar;
