import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loader from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Schema } from "rsuite";
import {
  Checkbox,
  CheckboxGroup,
  CheckPicker,
  Row,
  Col,
  Grid,
  Message,
  SelectPicker,
  Input,
  InputNumber,
  Button,
  Notification,
  Alert,
  ButtonToolbar,
  ButtonGroup,
} from "rsuite";

import Search from "../../../components/admin/Search";
import ErrorInput from "../../../components/admin/ErrorInput";
import GaleriaCategoria from "./galeria";
const API = new APLICLASS();

const CategoriaEditar = () => {
  const navigation = useNavigation();
  const params = navigation._getParam();
  const [loader, setLoader] = useStateIfMounted(false);
  const [noExiste, setNoExiste] = useStateIfMounted(false);
  const [section, setSection] = useStateIfMounted("info");

  const { StringType, NumberType, ObjectType, ArrayType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [nombre, setnombre] = useStateIfMounted("");
  const [nombreError, setnombreError] = useStateIfMounted(false);
  const [imagen, setImagen] = useStateIfMounted("");

  const [estatus, setEstatus] = useStateIfMounted("");

  const nombreModel = Schema.Model({
    nombre: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatenombre();
  }, [nombre]);
  const _validatenombre = () => {
    let errors = nombreModel.check({
      nombre: nombre,
    });
    if (errors.nombre.hasError) {
      let errorInfo = {
        hasError: errors.nombre.hasError,
        error: errors.nombre.hasError ? errors.nombre.errorMessage : "",
      };
      setnombreError(errorInfo);
    } else {
      setnombreError(false);
    }
  };
  // **********************************************************

  const _validate = () => {
    if (!nombreError && nombre != "") {
      return _save();
    }

    Notification["error"]({
      title: "Error en el formulario",
      description: <span>Verifica la validación del formulario</span>,
    });

    _validatenombre();
  };

  const _save = async () => {
    setLoader(true);

    let request = {
      nombre: nombre,
      estatus: estatus,
      id: params.id,
    };

    const { data } = await API.updateCategoria(request);

    if (data === true) {
      Alert.success("Registro agregado exitosamente.");
    } else {
      Alert.error("Hubo un error al agregar el registro.");
    }

    setLoader(false);
  };

  useEffect(() => {
    _getCategoria();
  }, []);

  const _getCategoria = async () => {
    const { data } = await API.getCategoriaDetalle(params.id);

    if (data !== false) {
      setnombre(data.categoria.nombre);
      setEstatus(data.categoria.estatus);
      setImagen(data.categoria.imagen_portada);
    } else {
      setNoExiste(true);
    }

    setLoader(false);
  };

  const _delete = async () => {
    const windowAlert = window.confirm(
      "Estás seguro que deseas eliminar esta categoría?"
    );
    if (windowAlert == true) {
      const request = {
        id: params.id,
      };
      const { data } = await API.deleteCategoria(request);
      if (data === true) {
        Alert.success("Registro eliminado exitosamente.");
        navigation._navigate("/categorias");
      } else {
        Alert.error("Ocurrió un error al borrar el registro.");
      }
    }
  };

  return (
    <MainLayout>
      <SectionTitle title="Editar categoría" caption={nombre}></SectionTitle>
      {noExiste ? (
        <Message
          type="error"
          title="Error"
          description={
            <p>
              El producto que estás buscando no existe o fue eliminado.
              <br />
              <a href="#" onClick={() => navigation._goBack()}>
                Regresar.
              </a>
            </p>
          }
        />
      ) : (
        <div>
          <div>
            <ButtonToolbar>
              <ButtonGroup block>
                <Button
                  onClick={() => setSection("galeria")}
                  appearance={section === "galeria" ? "primary" : "ghost"}
                  color="orange"
                >
                  Imagen
                </Button>
                <Button
                  onClick={() => setSection("info")}
                  appearance={section === "info" ? "primary" : "ghost"}
                  color="orange"
                >
                  Info
                </Button>
                <Button
                  onClick={() => setSection("estadisticas")}
                  appearance={section === "estadisticas" ? "primary" : "ghost"}
                  color="orange"
                >
                  Estadísticas
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </div>

          {section === "galeria" && (
            <GaleriaCategoria
              id={params.id}
              imagen={imagen}
              refresh={_getCategoria}
            ></GaleriaCategoria>
          )}

          {section === "info" && (
            <div>
              <Row gutter={48}>
                <h3 style={{ marginTop: 20 }}>Info</h3>
                <Col xs={16}>
                  <div className="input__item" style={{ marginTop: 0 }}>
                    <span>Nombre</span>
                    <Input
                      placeholder="Nombre del producto"
                      className={nombreError ? "input-error" : "input-no-error"}
                      value={nombre}
                      onChange={(val) => setnombre(val)}
                    />
                    {nombreError && <ErrorInput text={nombreError.error} />}
                  </div>

                  <div className="input__item">
                    <span>Estatus</span>
                    <SelectPicker
                      searchable={false}
                      value={estatus}
                      onChange={(val) => setEstatus(val)}
                      data={[
                        {
                          label: "Activo",
                          value: "1",
                        },
                        {
                          label: "Deshabilitado",
                          value: "0",
                        },
                      ]}
                      style={{ width: "100%" }}
                      placeholder="Selecciona el estatus"
                    />
                  </div>
                </Col>
                <Col xs={8}>
                  <div
                    className="input__item"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      appearance="primary"
                      size="lg"
                      onClick={() => _validate()}
                      block
                    >
                      Editar Categoría
                    </Button>
                  </div>

                  <div
                    className="input__item"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      appearance="ghost"
                      color="red"
                      size="lg"
                      onClick={() => _delete()}
                      block
                    >
                      Eliminar Categoría
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      )}
    </MainLayout>
  );
};

export default CategoriaEditar;
