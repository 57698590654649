import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import APICLASS from "../api/web";
import ScrollToTop from "../components/admin/ScrollToTop";
import ClienteState from "../state/user";
import ProductoLista from "../views/admin/products/lista";
import ProductoAgregar from "../views/admin/products/agregar";
import ProductoEditar from "../views/admin/products/editar";
import CategoriasLista from "../views/admin/categorias/lista";
import CategoriaAgregar from "../views/admin/categorias/agregar";
import CategoriaEditar from "../views/admin/categorias/editar";
import VehiculosLista from "../views/admin/vehiculos/lista";
import VehiculoAgregar from "../views/admin/vehiculos/agregar";
import VehiculoEditar from "../views/admin/vehiculos/editar";
import ClientesLista from "../views/admin/clientes/lista";
import ClienteDetalle from "../views/admin/clientes/detalle";
import ClienteAgregar from "../views/admin/clientes/agregar";
import ClientesEditar from "../views/admin/clientes/editar";
import VentasLista from "../views/admin/ventas/lista";
import PuntoDeVenta from "../views/admin/puntoVenta";
import VentaDetalle from "../views/admin/ventas/detalle";
import Dashboard from "../views/admin/dashboard";
import CuponesLista from "../views/admin/cupones/lista";
import CuponAgregar from "../views/admin/cupones/agregar";
import CuponEditar from "../views/admin/cupones/editar";
import PostsLista from "../views/admin/posts/lista";
import PostEditar from "../views/admin/posts/editar";
import ReporteProduccion from "../views/admin/reportes/produccion";
import ReporteGeneral from "../views/admin/reportes/general";

const API = new APICLASS();

const LockedNavigation = (props) => {
  const {
    cliente,
    setCliente,
    vehiculos,
    setVehiculos,
    setVehiculoSeleccionado,
  } = useContext(ClienteState);

  // useEffect(() => {
  //   if (localStorage.getItem("cuid") !== null) {
  //     _getCliente();
  //   }
  // }, []);

  // const _getCliente = async () => {
  //   const { data } = await API.getCliente(localStorage.getItem("cuid"));
  //   if (data.cliente !== false) {
  //     setCliente(data.cliente);
  //     setVehiculos(data.vehiculos);

  //     if (data.vehiculos.length === 1) {
  //       setVehiculoSeleccionado(data.vehiculos[0]);
  //     }
  //   }
  // };

  const navigation = useHistory;
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/productos">
            {(navigation) => {
              return <ProductoLista props={navigation}></ProductoLista>;
            }}
          </Route>

          <Route exact path="/punto-de-venta">
            {(navigation) => {
              return <PuntoDeVenta props={navigation}></PuntoDeVenta>;
            }}
          </Route>

          <Route exact path="/productos/agregar">
            {(navigation) => {
              return <ProductoAgregar props={navigation}></ProductoAgregar>;
            }}
          </Route>

          <Route exact path="/productos/:id">
            {(navigation) => {
              return <ProductoEditar props={navigation}></ProductoEditar>;
            }}
          </Route>

          <Route exact path="/categorias">
            {(navigation) => {
              return <CategoriasLista props={navigation}></CategoriasLista>;
            }}
          </Route>

          <Route exact path="/categorias/agregar">
            {(navigation) => {
              return <CategoriaAgregar props={navigation}></CategoriaAgregar>;
            }}
          </Route>

          <Route exact path="/categorias/:id">
            {(navigation) => {
              return <CategoriaEditar props={navigation}></CategoriaEditar>;
            }}
          </Route>

          <Route exact path="/clientes">
            {(navigation) => {
              return <ClientesLista props={navigation}></ClientesLista>;
            }}
          </Route>

          <Route exact path="/clientes/agregar">
            {(navigation) => {
              return <ClienteAgregar props={navigation}></ClienteAgregar>;
            }}
          </Route>

          <Route exact path="/clientes/:id">
            {(navigation) => {
              return <ClientesEditar props={navigation}></ClientesEditar>;
            }}
          </Route>

          <Route exact path="/clientes/detalle/:id">
            {(navigation) => {
              return <ClienteDetalle props={navigation}></ClienteDetalle>;
            }}
          </Route>

          <Route exact path="/vehiculos">
            {(navigation) => {
              return <VehiculosLista props={navigation}></VehiculosLista>;
            }}
          </Route>

          <Route exact path="/vehiculos/agregar">
            {(navigation) => {
              return <VehiculoAgregar props={navigation}></VehiculoAgregar>;
            }}
          </Route>

          <Route exact path="/vehiculos/:id">
            {(navigation) => {
              return <VehiculoEditar props={navigation}></VehiculoEditar>;
            }}
          </Route>

          <Route exact path="/ventas">
            {(navigation) => {
              return <VentasLista props={navigation}></VentasLista>;
            }}
          </Route>

          <Route exact path="/ventas/:id/:sname">
            {(navigation) => {
              return <VentaDetalle props={navigation}></VentaDetalle>;
            }}
          </Route>

          <Route exact path="/cupones">
            {(navigation) => {
              return <CuponesLista props={navigation}></CuponesLista>;
            }}
          </Route>

          <Route exact path="/reportes/produccion">
            {(navigation) => {
              return <ReporteProduccion props={navigation}></ReporteProduccion>;
            }}
          </Route>

          <Route exact path="/reportes/general">
            {(navigation) => {
              return <ReporteGeneral props={navigation}></ReporteGeneral>;
            }}
          </Route>

          <Route exact path="/cupones/agregar">
            {(navigation) => {
              return <CuponAgregar props={navigation}></CuponAgregar>;
            }}
          </Route>

          <Route exact path="/cupones/:id">
            {(navigation) => {
              return <CuponEditar props={navigation}></CuponEditar>;
            }}
          </Route>

          <Route exact path="/posts">
            {(navigation) => {
              return <PostsLista props={navigation}></PostsLista>;
            }}
          </Route>

          <Route exact path="/posts/:id">
            {(navigation) => {
              return <PostEditar props={navigation}></PostEditar>;
            }}
          </Route>

          <Route path="/">
            {(navigation) => {
              return <Dashboard props={navigation}></Dashboard>;
            }}
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default LockedNavigation;
