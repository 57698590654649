import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loader from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Schema } from "rsuite";
import {
  Checkbox,
  CheckboxGroup,
  CheckPicker,
  Row,
  Col,
  Grid,
  Message,
  SelectPicker,
  Input,
  InputNumber,
  Button,
  Notification,
  Form,
  Alert,
} from "rsuite";

import Search from "../../../components/admin/Search";
import ErrorInput from "../../../components/admin/ErrorInput";
import APICLASS from "../../../api/web";
import APIWORLDCLASS from "../../../api/world";
const API = new APLICLASS();
const APIWORLD = new APIWORLDCLASS();

const ClientesEditar = () => {
  const navigation = useNavigation();

  const { id } = navigation._getParam();
  const [cliente, setCliente] = useStateIfMounted(null);
  const [loader, setLoader] = useStateIfMounted(false);
  const [company, setCompany] = useStateIfMounted("");

  const [zip, setZip] = useStateIfMounted("");
  const [ciudad, setCiudad] = useStateIfMounted("");
  const [pais, setPais] = useStateIfMounted("");
  const [estado, setEstado] = useStateIfMounted("");

  const [paises, setPaises] = useStateIfMounted([]);
  const [estados, setEstados] = useStateIfMounted([]);
  const [ciudades, setCiudades] = useStateIfMounted([]);

  const [adressXtra, setAdressXtra] = useStateIfMounted("");
  const [loaderPaises, setLoaderPaises] = useStateIfMounted(true);

  const [error, setError] = useStateIfMounted(false);

  const { StringType, NumberType, ObjectType, ArrayType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [name, setname] = useStateIfMounted("");
  const [nameError, setnameError] = useStateIfMounted(false);
  const nameModel = Schema.Model({
    name: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatename();
  }, [name]);
  const _validatename = () => {
    let errors = nameModel.check({
      name: name,
    });
    if (errors.name.hasError) {
      let errorInfo = {
        hasError: errors.name.hasError,
        error: errors.name.hasError ? errors.name.errorMessage : "",
      };
      setnameError(errorInfo);
    } else {
      setnameError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [lastName, setlastName] = useStateIfMounted("");
  const [lastNameError, setlastNameError] = useStateIfMounted(false);
  const lastNameModel = Schema.Model({
    lastName: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatelastName();
  }, [lastName]);
  const _validatelastName = () => {
    let errors = lastNameModel.check({
      lastName: lastName,
    });
    if (errors.lastName.hasError) {
      let errorInfo = {
        hasError: errors.lastName.hasError,
        error: errors.lastName.hasError ? errors.lastName.errorMessage : "",
      };
      setlastNameError(errorInfo);
    } else {
      setlastNameError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [phone, setphone] = useStateIfMounted("");
  const [phoneError, setphoneError] = useStateIfMounted(false);
  const phoneModel = Schema.Model({
    phone: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatephone();
  }, [phone]);
  const _validatephone = () => {
    let errors = phoneModel.check({
      phone: phone,
    });
    if (errors.phone.hasError) {
      let errorInfo = {
        hasError: errors.phone.hasError,
        error: errors.phone.hasError ? errors.phone.errorMessage : "",
      };
      setphoneError(errorInfo);
    } else {
      setphoneError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [countryID, setcountryID] = useStateIfMounted("");
  const [countryIDError, setcountryIDError] = useStateIfMounted(false);
  const countryIDModel = Schema.Model({
    countryID: ObjectType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatecountryID();
  }, [countryID]);
  const _validatecountryID = () => {
    let errors = countryIDModel.check({
      countryID: countryID,
    });
    if (errors.countryID.hasError) {
      let errorInfo = {
        hasError: errors.countryID.hasError,
        error: errors.countryID.hasError ? errors.countryID.errorMessage : "",
      };
      setcountryIDError(errorInfo);
    } else {
      setcountryIDError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [stateID, setstateID] = useStateIfMounted("");
  const [stateIDError, setstateIDError] = useStateIfMounted(false);
  const stateIDModel = Schema.Model({
    stateID: ObjectType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatestateID();
  }, [stateID]);
  const _validatestateID = () => {
    let errors = stateIDModel.check({
      stateID: stateID,
    });
    if (errors.stateID.hasError) {
      let errorInfo = {
        hasError: errors.stateID.hasError,
        error: errors.stateID.hasError ? errors.stateID.errorMessage : "",
      };
      setstateIDError(errorInfo);
    } else {
      setstateIDError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [cityID, setcityID] = useStateIfMounted("");
  const [cityIDError, setcityIDError] = useStateIfMounted(false);
  const cityIDModel = Schema.Model({
    cityID: ObjectType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatecityID();
  }, [cityID]);
  const _validatecityID = () => {
    let errors = cityIDModel.check({
      cityID: cityID,
    });
    if (errors.cityID.hasError) {
      let errorInfo = {
        hasError: errors.cityID.hasError,
        error: errors.cityID.hasError ? errors.cityID.errorMessage : "",
      };
      setcityIDError(errorInfo);
    } else {
      setcityIDError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [street, setstreet] = useStateIfMounted("");
  const [streetError, setstreetError] = useStateIfMounted(false);
  const streetModel = Schema.Model({
    street: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatestreet();
  }, [street]);
  const _validatestreet = () => {
    let errors = streetModel.check({
      street: street,
    });
    if (errors.street.hasError) {
      let errorInfo = {
        hasError: errors.street.hasError,
        error: errors.street.hasError ? errors.street.errorMessage : "",
      };
      setstreetError(errorInfo);
    } else {
      setstreetError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [email, setemail] = useStateIfMounted("");
  const [emailError, setemailError] = useStateIfMounted(false);
  const emailModel = Schema.Model({
    email: StringType()
      .isRequired("Este campo es requerido")
      .isEmail("Ingresa un email válido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateemail();
  }, [email]);
  const _validateemail = () => {
    let errors = emailModel.check({
      email: email,
    });
    if (errors.email.hasError) {
      let errorInfo = {
        hasError: errors.email.hasError,
        error: errors.email.hasError ? errors.email.errorMessage : "",
      };
      setemailError(errorInfo);
    } else {
      setemailError(false);
    }
  };
  // **********************************************************

  useEffect(() => {
    _getPaises();
  }, []);

  const _getPaises = async () => {
    const { data } = await APIWORLD.getPaises();
    if (data !== false) {
      const paisesArr = [];
      data.map((item) => {
        let itemData = {
          label: item.name,
          value: { name: item.name, id: item.id },
        };

        paisesArr.push(itemData);
      });

      setPaises(paisesArr);
    }
    setLoaderPaises(false);
  };

  useEffect(() => {
    if (countryID !== false || countryID !== null) {
      _getEstados();
    }
  }, [countryID]);

  const _getEstados = async () => {
    setLoaderPaises(true);
    setEstados([]);
    setCiudades([]);
    const { data } = await APIWORLD.getEstadosDelPais(countryID.id);
    if (data !== false) {
      const estadosArr = [];
      data.map((item) => {
        let itemData = {
          label: item.name,
          value: { id: item.id, name: item.name },
        };

        estadosArr.push(itemData);
      });

      setEstados(estadosArr);
    }
    setLoaderPaises(false);
  };

  useEffect(() => {
    if (stateID !== false || stateID !== null) {
      _getCiudades();
    }
  }, [stateID]);

  const _getCiudades = async () => {
    setLoaderPaises(true);
    const { data } = await APIWORLD.getCiudadesDelEstado(stateID.id);
    if (data !== false) {
      const ciudadesArr = [];
      data.map((item) => {
        let itemData = {
          label: item.name,
          value: { name: item.name, id: item.id },
        };

        ciudadesArr.push(itemData);
      });

      setCiudades(ciudadesArr);
    }
    setLoaderPaises(false);
  };

  const _setPais = (item) => {
    setPais(item.name);
    setcountryID(item);
  };

  const _setCiudad = (item) => {
    setCiudad(item.name);
    setcityID(item);
  };

  const _setEstado = (item) => {
    setEstado(item.name);
    setstateID(item);
  };

  const _validate = () => {
    if (
      !nameError &&
      !lastNameError &&
      !phoneError &&
      !countryIDError &&
      !stateIDError &&
      !streetError &&
      !emailError &&
      name != "" &&
      lastName !== "" &&
      phone !== "" &&
      countryID !== "" &&
      stateID !== "" &&
      street !== "" &&
      email !== ""
    ) {
      return _save();
    }

    Notification["error"]({
      title: "Error en el formulario",
      description: <span>Verifica la validación del formulario</span>,
    });

    _validatename();
    _validatelastName();
    _validatephone();
    _validatecountryID();
    _validatestateID();
    _validatestreet();
    _validateemail();
  };

  const _save = async () => {
    setLoader(true);

    const request = {
      nombre: name,
      apellidos: lastName,
      direccion_calle: street === "" ? null : street,
      empresa: company === "" ? null : company,
      id_ciudad: cityID === "" ? null : cityID.id,
      id_state: stateID === "" ? null : stateID.id,
      zip: zip === "" ? null : zip,
      id_pais: countryID === "" ? null : countryID.id,
      telefono: phone === "" ? null : phone,
      direccion_extra: adressXtra === "" ? null : adressXtra,
      estado: estado,
      ciudad: ciudad === "" ? null : ciudad,
      pais: pais,
      email: email,
      id_cliente: id,
    };

    const { data } = await API.updateCliente(request);

    if (data.error) {
      Alert.error(data.errorText, 4000);
    } else {
      Alert.success("Registro agregado exitosamente.");
      navigation._navigate(`/clientes`);
    }

    setLoader(false);
  };

  useEffect(() => {
    _getCliente();
  }, []);

  const _getCliente = async () => {
    const { data } = await API.getCliente(id);
    if (data !== false) {
      setname(data.cliente.nombre);
      setlastName(data.cliente.apellidos);
      setphone(data.cliente.telefono);
      setemail(data.cliente.email);
      setZip(data.cliente.zip);
      setAdressXtra(data.cliente.direccion_extra);
      setstreet(data.cliente.direccion_calle);
      setcountryID({
        id: data.cliente.id_pais,
        name: data.cliente.pais,
      });

      setstateID({
        id: data.cliente.id_state,
        name: data.cliente.estado,
      });

      setcityID({
        id: data.cliente.id_ciudad,
        name: data.cliente.ciudad,
      });
    }
  };

  return (
    <MainLayout>
      <SectionTitle
        title="Editar cliente"
        caption="Ingresa los campos requeridos para editar el cliente"
      ></SectionTitle>

      <Row gutter={48}>
        <Col xs={24}>
          {loaderPaises ? (
            <Loader text="Loading data..."></Loader>
          ) : (
            <div className="form-box form-box--transparent">
              <Row>
                <Col xs={24}>
                  <Form>
                    <Row>
                      <Col xs={12}>
                        <div className="form__item">
                          <span className="form__item__label">Name</span>
                          <Input
                            value={name}
                            onChange={(val) => setname(val)}
                            placeholder="Name"
                            className={
                              nameError ? "input-error" : "input-no-error"
                            }
                          ></Input>
                          {nameError && <ErrorInput text={nameError.error} />}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form__item">
                          <span className="form__item__label">Last Name</span>
                          <Input
                            value={lastName}
                            onChange={(val) => setlastName(val)}
                            placeholder="Last Name "
                            className={
                              nameError ? "input-error" : "input-no-error"
                            }
                          ></Input>
                          {lastNameError && (
                            <ErrorInput text={lastNameError.error} />
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <div className="form__item">
                          <span className="form__item__label">
                            Phone number
                          </span>
                          <Input
                            value={phone}
                            onChange={(val) => setphone(val)}
                            placeholder="Phone number"
                            className={
                              nameError ? "input-error" : "input-no-error"
                            }
                          ></Input>
                          {phoneError && <ErrorInput text={phoneError.error} />}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form__item">
                          <span className="form__item__label">Email</span>
                          <Input
                            disabled
                            value={email}
                            onChange={(val) => setemail(val)}
                            placeholder="Phone number"
                            className={
                              emailError ? "input-error" : "input-no-error"
                            }
                          ></Input>
                          {emailError && <ErrorInput text={emailError.error} />}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <div className="form__item">
                          <span className="form__item__label">Country</span>
                          <SelectPicker
                            searchable
                            data={paises}
                            appearance="default"
                            placeholder="Select the coutry"
                            cleanable={false}
                            value={countryID}
                            onChange={(val) => _setPais(val)}
                            style={{ width: "100%" }}
                            className={
                              countryIDError ? "input-error" : "input-no-error"
                            }
                          />
                          {countryIDError && (
                            <ErrorInput text={countryIDError.error} />
                          )}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form__item">
                          <span className="form__item__label">State</span>
                          <SelectPicker
                            data={estados}
                            appearance="default"
                            placeholder="Select the stateID"
                            cleanable={false}
                            value={stateID}
                            onChange={(val) => _setEstado(val)}
                            searchable={true}
                            style={{ width: "100%" }}
                            className={
                              stateIDError ? "input-error" : "input-no-error"
                            }
                          />
                          {stateIDError && (
                            <ErrorInput text={stateIDError.error} />
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <div className="form__item">
                          <span className="form__item__label">Town/City</span>
                          <SelectPicker
                            searchable
                            data={ciudades}
                            appearance="default"
                            placeholder="Select the Town/City"
                            cleanable={false}
                            value={cityID}
                            onChange={(val) => _setCiudad(val)}
                            style={{ width: "100%" }}
                            className={
                              cityIDError ? "input-error" : "input-no-error"
                            }
                          />
                          {cityIDError && (
                            <ErrorInput text={cityIDError.error} />
                          )}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form__item">
                          <span className="form__item__label">
                            Street Adress{" "}
                          </span>
                          <Input
                            value={street}
                            onChange={(val) => setstreet(val)}
                            placeholder="Street adress"
                            className={
                              streetError ? "input-error" : "input-no-error"
                            }
                          ></Input>
                          {streetError && (
                            <ErrorInput text={streetError.error} />
                          )}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form__item">
                          <span className="form__item__label">
                            Apartment, Suite, Unit, etc.
                          </span>
                          <Input
                            value={adressXtra}
                            onChange={(val) => setAdressXtra(val)}
                            placeholder="Extra info adress"
                          ></Input>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="form__item">
                          <span className="form__item__label">
                            Post Code / ZIP{" "}
                          </span>
                          <Input
                            value={zip}
                            onChange={(val) => setZip(val)}
                            placeholder="Post code / zip"
                          ></Input>
                        </div>
                      </Col>
                    </Row>

                    <div
                      className="input__item"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        loading={loader}
                        style={{ width: 250 }}
                        appearance="primary"
                        size="lg"
                        onClick={() => _validate()}
                      >
                        Guardar cliente
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </MainLayout>
  );
};

export default ClientesEditar;
