import React, { useEffect } from "react";
import {
  Row,
  Col,
  Icon,
  IconButton,
  Tag,
  Uploader,
  Message,
  Notification,
  Alert,
} from "rsuite";
import { Table } from "react-bootstrap";
import { useStateIfMounted } from "use-state-if-mounted";
import api from "../../../constants/api";
import pathImages from "../../../constants/pathImages";
import { Link } from "react-router-dom";

import APLICLASS from "../../../api/web";
const API = new APLICLASS();

const GaleriaVehiculo = (props) => {
  const [imagen, setImagen] = useStateIfMounted("");
  const [loader, setLoader] = useStateIfMounted(false);

  useEffect(() => {
    setImagen(props.imagen);
  }, [props.imagen]);

  const _delete = async (id_imagen) => {
    const windowAlert = window.confirm(
      "Estás seguro que deseas eliminar esta imagen?"
    );
    if (windowAlert == true) {
      setLoader(true);

      let request = {
        id: props.id,
      };

      const { data } = await API.removeImagenPortada(request);

      if (data === true) {
        Alert.success("El registro se eliminó exitosamente");
        props.refresh();
      } else {
        Alert.error("Ocurrió un error al eliminar el registro");
      }

      setLoader(false);
    }
  };

  return (
    <div>
      <h3 style={{ marginTop: 20 }}>Imagen destacada</h3>
      <Row>
        <Col xs={24}>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
          >
            <span style={{ fontWeight: "bold" }}>Agregar nueva imagen</span>
            <Uploader
              style={{ marginLeft: 10 }}
              listType="picture"
              onSuccess={() => {
                props.refresh();
                Alert.success("La imagen se guardó exitosamente");
              }}
              action={`${api}`}
              data={{
                ot: "SaveImagenVehiculo",
                app: "admin",
                id_vehiculo: props.id,
              }}
              name="img"
            >
              <button>
                <Icon icon="camera-retro" size="lg" />
              </button>
            </Uploader>
          </div>
        </Col>
        <Col xs={24}>
          <div>
            {props.imagen === null || props.imagen === "" ? (
              <Message
                showIcon
                type="warning"
                title="Warning"
                description={
                  <p>No has agregado imágenes a este producto aún</p>
                }
              />
            ) : (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Imagen</th>
                    <th>URL</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div
                        style={{
                          backgroundImage: `url(${pathImages}/${imagen})`,
                        }}
                        className="table__image"
                      ></div>
                    </td>
                    <td style={{ paddingTop: 25 }}>
                      <a href={`${pathImages}/${imagen}`} target="_blank">
                        {`${pathImages}/${imagen}`}
                      </a>
                    </td>
                    <td style={{ paddingTop: 19 }}>
                      <IconButton
                        style={{ marginLeft: 10 }}
                        icon={<Icon icon="trash" />}
                        circle
                        size="sm"
                        color="red"
                        onClick={() => _delete()}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GaleriaVehiculo;
