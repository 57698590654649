import React from "react";
import { Message } from "rsuite";

const ErrorInput = (props) => {
  return (
    <div style={{ marginTop: 5 }}>
      <Message showIcon type="error" description={props.text} />
    </div>
  );
};

export default ErrorInput;
