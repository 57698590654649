import React from "react";
import { Form } from "react-bootstrap";
import { Input } from "rsuite";
import { FiSearch } from "react-icons/fi";

const Search = (props) => {
  return (
    <div className="search-bar">
      <div className="search-bar__icon">
        <FiSearch></FiSearch>
      </div>

      <div className="search-bar__input">
        <Input
          value={props.value}
          onChange={(val) => props.updateInputFunction(val)}
          placeholder="Buscar por nombre"
          style={{ backgroundColor: "#f0f0f0" }}
        ></Input>
      </div>
    </div>
  );
};

export default Search;
