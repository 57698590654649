import moment from "moment";
import "moment/locale/es";
import React, { useEffect } from "react";
import { Col, Row } from "rsuite";
import { useStateIfMounted } from "use-state-if-mounted";
import APLICLASS from "../../../api/web";
import MainLayout from "../../../components/admin/Layout/main";
import VentasInfo from "../../../components/admin/Ventas";
import useFecha from "../../../hooks/useFecha";
import useNavigation from "../../../hooks/useNavigation";
const API = new APLICLASS();

const Dashboard = () => {
  const fecha = useFecha();
  const [loader, setLoader] = useStateIfMounted(true);
  const [numeroVentas, setNumeroVentas] = useStateIfMounted("");
  const [ventasTotales, setVentasTotales] = useStateIfMounted("");
  const [productoMasVendido, setProductoMasVendido] = useStateIfMounted("");
  const [clientes, setClientes] = useStateIfMounted([]);
  const [pedidos, setPedidos] = useStateIfMounted([]);

  const navigation = useNavigation();

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    setLoader(true);
    await _getPedidos();
    await _getClientes();
    await _getVentasMes();
    setLoader(false);
  };

  const _getVentasMes = async () => {
    setLoader(true);
    const fecha = moment().format("Y-MM-DD");
    const { data } = await API.getVentasHistorial(fecha);

    if (Array.isArray(data.Ventas)) {
      setNumeroVentas(data.Total_Ventas);
      setVentasTotales(data.Monto);
      setProductoMasVendido(data.ProductoMasVendido[0]);
    }

    setLoader(false);
  };

  const _getPedidos = async () => {
    const { data } = await API.getVentas();
    setPedidos(data);
  };

  const _getClientes = async () => {
    const { data } = await API.getClientes();
    setClientes(data);
  };

  return (
    <MainLayout
      section="Dashboard"
      bread={[
        {
          link: "/",
          name: "Inicio",
          active: true,
        },
      ]}
    >
      <div className="dashboard">
        <div className="dashboard__saludo">
          <div className="dashboard__saludo__nombre">
            <h1>Hola Fernando</h1>
            <h4>{fecha.convertirFormatoRegular(moment())}</h4>
          </div>
        </div>
        <Row>
          {/* ===== IZQUIERDA ===== */}
          <Col xs={16}>
            <VentasInfo
              mb
              mt
              total={ventasTotales}
              productoMasVendido={productoMasVendido}
              numeroVentas={numeroVentas}
              tipoReporte={"Mes"}
            ></VentasInfo>
            <div className="dashboard-box" style={{ marginTop: 20 }}>
              <h4 style={{ fontWeight: "bold", marginBottom: 10 }}>
                Últimos clientes registrados
              </h4>

              {clientes.length > 0 ? (
                <div>
                  {clientes.map((item, index) => {
                    return (
                      <div className="lista-box-pedido">
                        <h5>
                          {item.nombre} {item.apellidos}
                        </h5>
                        <h6>{item.email}</h6>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>No encontramos clientes</div>
              )}
            </div>
          </Col>
          {/* ===== /IZQUIERDA ===== */}

          {/* ===== DERECHA ===== */}
          <Col xs={8} style={{ marginTop: 20 }}>
            <div className="dashboard-box">
              <h4 style={{ fontWeight: "bold", marginBottom: 10 }}>
                Últimos pedidos
              </h4>

              {pedidos.length > 0 ? (
                <div>
                  {pedidos.map((item, index) => {
                    if (index < 11) {
                      return (
                        <div
                          className="lista-box-pedido"
                          onClick={() =>
                            navigation._navigate(`/ventas/${item.id}/info`)
                          }
                        >
                          <h5>
                            {item.nombre} {item.apellidos}
                          </h5>
                          <h6>${item.total}</h6>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              ) : (
                <div>No encontramos clientes</div>
              )}
            </div>
          </Col>
          {/* ===== /DERECHA ===== */}
        </Row>
      </div>
    </MainLayout>
  );
};

export default Dashboard;
