import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loader from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Schema } from "rsuite";
import {
  Checkbox,
  CheckboxGroup,
  CheckPicker,
  Row,
  Col,
  Grid,
  Message,
  SelectPicker,
  Input,
  InputNumber,
  Button,
  Notification,
  Alert,
} from "rsuite";

import Search from "../../../components/admin/Search";
import ErrorInput from "../../../components/admin/ErrorInput";
import Loading from "../../../components/admin/Loader";
const API = new APLICLASS();

const ProductoAgregar = () => {
  const navigation = useNavigation();
  const [loader, setLoader] = useStateIfMounted(false);
  const [categorias, setCategorias] = useStateIfMounted([]);
  const [vehiculos, setVehiculos] = useStateIfMounted([]);
  const [estatus, setEstatus] = useStateIfMounted(1);
  const [descripcion, setDescripcion] = useStateIfMounted("");
  const [vehiculosSeleccionados, setvehiculosSeleccionados] = useStateIfMounted(
    []
  );

  const { StringType, NumberType, ObjectType, ArrayType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [nombre, setnombre] = useStateIfMounted("");
  const [nombreError, setnombreError] = useStateIfMounted(false);
  const nombreModel = Schema.Model({
    nombre: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatenombre();
  }, [nombre]);
  const _validatenombre = () => {
    let errors = nombreModel.check({
      nombre: nombre,
    });
    if (errors.nombre.hasError) {
      let errorInfo = {
        hasError: errors.nombre.hasError,
        error: errors.nombre.hasError ? errors.nombre.errorMessage : "",
      };
      setnombreError(errorInfo);
    } else {
      setnombreError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [precio, setprecio] = useStateIfMounted("");
  const [precioError, setprecioError] = useStateIfMounted(false);
  const precioModel = Schema.Model({
    precio: NumberType("Ingresa un número válido").isRequired(
      "Este campo es requerido"
    ),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateprecio();
  }, [precio]);
  const _validateprecio = () => {
    let errors = precioModel.check({
      precio: precio,
    });
    if (errors.precio.hasError) {
      let errorInfo = {
        hasError: errors.precio.hasError,
        error: errors.precio.hasError ? errors.precio.errorMessage : "",
      };
      setprecioError(errorInfo);
    } else {
      setprecioError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [precioOferta, setprecioOferta] = useStateIfMounted("");
  const [precioOfertaError, setprecioOfertaError] = useStateIfMounted(false);
  const precioOfertaModel = Schema.Model({
    precioOferta: NumberType(),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateprecioOferta();
  }, [precioOferta]);
  const _validateprecioOferta = () => {
    let errors = precioOfertaModel.check({
      precioOferta: precioOferta,
    });
    if (errors.precioOferta.hasError) {
      let errorInfo = {
        hasError: errors.precioOferta.hasError,
        error: errors.precioOferta.hasError
          ? errors.precioOferta.errorMessage
          : "",
      };
      setprecioOfertaError(errorInfo);
    } else {
      setprecioOfertaError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [peso, setpeso] = useStateIfMounted("50");
  const [pesoError, setpesoError] = useStateIfMounted(false);
  const pesoModel = Schema.Model({
    peso: NumberType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatepeso();
  }, [peso]);
  const _validatepeso = () => {
    let errors = pesoModel.check({
      peso: peso,
    });
    if (errors.peso.hasError) {
      let errorInfo = {
        hasError: errors.peso.hasError,
        error: errors.peso.hasError ? errors.peso.errorMessage : "",
      };
      setpesoError(errorInfo);
    } else {
      setpesoError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [largo, setlargo] = useStateIfMounted("50");
  const [largoError, setlargoError] = useStateIfMounted(false);
  const largoModel = Schema.Model({
    largo: NumberType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatelargo();
  }, [largo]);
  const _validatelargo = () => {
    let errors = largoModel.check({
      largo: largo,
    });
    if (errors.largo.hasError) {
      let errorInfo = {
        hasError: errors.largo.hasError,
        error: errors.largo.hasError ? errors.largo.errorMessage : "",
      };
      setlargoError(errorInfo);
    } else {
      setlargoError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [ancho, setancho] = useStateIfMounted("50");
  const [anchoError, setanchoError] = useStateIfMounted(false);
  const anchoModel = Schema.Model({
    ancho: NumberType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateancho();
  }, [ancho]);
  const _validateancho = () => {
    let errors = anchoModel.check({
      ancho: ancho,
    });
    if (errors.ancho.hasError) {
      let errorInfo = {
        hasError: errors.ancho.hasError,
        error: errors.ancho.hasError ? errors.ancho.errorMessage : "",
      };
      setanchoError(errorInfo);
    } else {
      setanchoError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [alto, setalto] = useStateIfMounted("50");
  const [altoError, setaltoError] = useStateIfMounted(false);
  const altoModel = Schema.Model({
    alto: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatealto();
  }, [alto]);
  const _validatealto = () => {
    let errors = altoModel.check({
      alto: alto,
    });
    if (errors.alto.hasError) {
      let errorInfo = {
        hasError: errors.alto.hasError,
        error: errors.alto.hasError ? errors.alto.errorMessage : "",
      };
      setaltoError(errorInfo);
    } else {
      setaltoError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [stock, setstock] = useStateIfMounted("50");
  const [stockError, setstockError] = useStateIfMounted(false);
  const stockModel = Schema.Model({
    stock: NumberType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatestock();
  }, [stock]);
  const _validatestock = () => {
    let errors = stockModel.check({
      stock: stock,
    });
    if (errors.stock.hasError) {
      let errorInfo = {
        hasError: errors.stock.hasError,
        error: errors.stock.hasError ? errors.stock.errorMessage : "",
      };
      setstockError(errorInfo);
    } else {
      setstockError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [categoriaSelccionada, setcategoriaSelccionada] = useStateIfMounted("");
  const [categoriaSelccionadaError, setcategoriaSelccionadaError] =
    useStateIfMounted(false);
  const categoriaSelccionadaModel = Schema.Model({
    categoriaSelccionada: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatecategoriaSelccionada();
  }, [categoriaSelccionada]);
  const _validatecategoriaSelccionada = () => {
    let errors = categoriaSelccionadaModel.check({
      categoriaSelccionada: categoriaSelccionada,
    });
    if (errors.categoriaSelccionada.hasError) {
      let errorInfo = {
        hasError: errors.categoriaSelccionada.hasError,
        error: errors.categoriaSelccionada.hasError
          ? errors.categoriaSelccionada.errorMessage
          : "",
      };
      setcategoriaSelccionadaError(errorInfo);
    } else {
      setcategoriaSelccionadaError(false);
    }
  };
  // **********************************************************

  useEffect(() => {
    _getCategorias();
    _getVehiculos();
  }, []);

  const _getCategorias = async () => {
    const { data } = await API.getCategorias();

    if (data.length > 0) {
      const dataCategorias = [];
      data.map((item) => {
        let itemParsed = {
          label: item.nombre,
          value: item.id,
        };
        dataCategorias.push(itemParsed);
      });

      setCategorias(dataCategorias);
    }
  };

  const _getVehiculos = async () => {
    const { data } = await API.getVehiculos();

    if (data.length > 0) {
      const dataVehiculos = [];
      data.map((item) => {
        let itemParsed = {
          label: item.nombre,
          value: item.id,
        };
        dataVehiculos.push(itemParsed);
      });

      setVehiculos(dataVehiculos);
    }
  };

  const _validate = () => {
    if (
      !nombreError &&
      !precioError &&
      !precioOfertaError &&
      !pesoError &&
      !largoError &&
      !altoError &&
      !anchoError &&
      !stockError &&
      !categoriaSelccionadaError &&
      nombre != "" &&
      precio != "" &&
      peso != "" &&
      largo != "" &&
      alto != "" &&
      ancho != "" &&
      stock != "" &&
      categoriaSelccionada != ""
    ) {
      return _save();
    }

    Notification["error"]({
      title: "Error en el formulario",
      description: <span>Verifica la validación del formulario</span>,
    });

    _validatenombre();
    _validateprecio();
    _validateprecioOferta();
    _validatepeso();
    _validatelargo();
    _validatealto();
    _validateancho();
    _validatestock();
    _validatecategoriaSelccionada();
  };

  const _save = async () => {
    setLoader(true);

    let request = {
      nombre: nombre,
      precio: precio,
      precio_oferta: precioOferta !== "" ? precioOferta : null,
      peso: peso,
      largo: largo,
      alto: alto,
      ancho: ancho,
      stock: stock,
      categoria: categoriaSelccionada,
      vehiculos:
        vehiculosSeleccionados.length > 0 ? vehiculosSeleccionados : false,
      estatus: estatus,
      descripcion: descripcion,
    };

    const { data } = await API.saveProducto(request);

    if (data.status === true) {
      Alert.success("Registro agregado exitosamente.");
      navigation._navigate(`/productos/${data.id}`);
    } else {
      Alert.error("Hubo un error al agregar el registro.");
    }

    setLoader(false);
  };

  return (
    <MainLayout>
      <SectionTitle
        title="Agregar producto"
        caption="Ingresa los campos requeridos para crear un nuevo producto"
      ></SectionTitle>

      <Row gutter={48}>
        <Col xs={16}>
          <div className="input__item">
            <span>Nombre</span>
            <Input
              placeholder="Nombre del producto"
              className={nombreError ? "input-error" : "input-no-error"}
              value={nombre}
              onChange={(val) => setnombre(val)}
            />
            {nombreError && <ErrorInput text={nombreError.error} />}
          </div>

          <Row>
            <Col xs={12}>
              <div className="input__item">
                <span>Precio</span>
                <InputNumber
                  className={precioError ? "input-error" : "input-no-error"}
                  value={precio}
                  onChange={(val) => setprecio(val)}
                  placeholder="Precio del producto"
                />
                {precioError && <ErrorInput text={precioError.error} />}
              </div>
            </Col>
            <Col xs={12}>
              <div className="input__item">
                <span>Precio de oferta</span>
                <InputNumber
                  className={
                    precioOfertaError ? "input-error" : "input-no-error"
                  }
                  value={precioOferta}
                  onChange={(val) => setprecioOferta(val)}
                  placeholder="Precio de oferta del producto"
                />
                {precioOfertaError && (
                  <ErrorInput text={precioOfertaError.error} />
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div className="input__item">
                <span>Peso</span>
                <InputNumber
                  placeholder="Peso del producto"
                  className={pesoError ? "input-error" : "input-no-error"}
                  value={peso}
                  onChange={(val) => setpeso(val)}
                />
                {pesoError && <ErrorInput text={pesoError.error} />}
              </div>
            </Col>
            <Col xs={12}>
              <div className="input__item">
                <span>Largo</span>
                <InputNumber
                  placeholder="Largo del producto"
                  className={largoError ? "input-error" : "input-no-error"}
                  value={largo}
                  onChange={(val) => setlargo(val)}
                />
                {largoError && <ErrorInput text={largoError.error} />}
              </div>
            </Col>
            <Col xs={12}>
              <div className="input__item">
                <span>Ancho</span>
                <InputNumber
                  className={anchoError ? "input-error" : "input-no-error"}
                  value={ancho}
                  onChange={(val) => setancho(val)}
                  placeholder="Ancho del producto"
                />
                {anchoError && <ErrorInput text={anchoError.error} />}
              </div>
            </Col>
            <Col xs={12}>
              <div className="input__item">
                <span>Alto</span>
                <InputNumber
                  placeholder="Alto del producto"
                  className={altoError ? "input-error" : "input-no-error"}
                  value={alto}
                  onChange={(val) => setalto(val)}
                />
                {altoError && <ErrorInput text={altoError.error} />}
              </div>
            </Col>
          </Row>

          <div className="input__item">
            <span>Stock</span>
            <InputNumber
              placeholder="Cantidad de stock del producto"
              className={stockError ? "input-error" : "input-no-error"}
              value={stock}
              onChange={(val) => setstock(val)}
            />
            {stockError && <ErrorInput text={stockError.error} />}
          </div>

          <div className="input__item">
            <span>Estatus</span>
            <SelectPicker
              searchable={false}
              value={estatus}
              onChange={(val) => setEstatus(val)}
              data={[
                {
                  label: "Activo",
                  value: 1,
                },
                {
                  label: "Deshabilitado",
                  value: 0,
                },
              ]}
              style={{ width: "100%" }}
              placeholder="Selecciona el estatus"
            />
          </div>

          <div className="input__item">
            <span>Descripción</span>
            <Input
              value={descripcion}
              onChange={(val) => setDescripcion(val)}
              rows={5}
              name="textarea"
              componentClass="textarea"
            />
          </div>
        </Col>
        <Col xs={8}>
          <div>
            {loader ? (
              <div>cargando categorías</div>
            ) : (
              <div>
                {categorias.length > 0 ? (
                  <div className="input__item">
                    <span>Categoría</span>
                    <SelectPicker
                      searchable={true}
                      data={categorias}
                      style={{ width: "100%" }}
                      placeholder="Selecciona la categoría"
                      className={
                        categoriaSelccionadaError
                          ? "input-error"
                          : "input-no-error"
                      }
                      value={categoriaSelccionada}
                      onChange={(val) => setcategoriaSelccionada(val)}
                    />
                    {categoriaSelccionadaError && (
                      <ErrorInput text={categoriaSelccionadaError.error} />
                    )}
                  </div>
                ) : (
                  <Message
                    showIcon
                    type="error"
                    description="No se han agregado categorías, no podrás agregar productos hasta que existan categorías disponibles"
                  />
                )}
              </div>
            )}
          </div>

          <div className="input__item">
            {loader ? (
              <Loading></Loading>
            ) : (
              <div>
                {vehiculos.length > 0 ? (
                  <div>
                    <span>Vehículos compatibles</span>
                    <CheckPicker
                      data={vehiculos}
                      placeholder="Selecciona los vehículos"
                      value={vehiculosSeleccionados}
                      onChange={(val) => setvehiculosSeleccionados(val)}
                      style={{ width: "100%" }}
                    />
                  </div>
                ) : (
                  <Message
                    showIcon
                    type="error"
                    description="No se han agregado vehículos, no podrás agregar productos hasta que existan vehículos disponibles"
                  />
                )}
              </div>
            )}
          </div>

          <div
            className="input__item"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              block
              appearance="primary"
              size="lg"
              onClick={() => _validate()}
            >
              Guardar Producto
            </Button>
          </div>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default ProductoAgregar;
