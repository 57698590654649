import React from "react";
import {
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

const useNavigation = () => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const match = useRouteMatch();

  const _navigate = (link) => {
    history.push(link);
  };

  const _replace = (link) => {
    history.replace(link);
  };

  const _getParam = () => {
    return params;
  };

  const _getLocation = () => {
    return location;
  };

  const _getMatch = () => {
    return match;
  };

  const _goBack = () => {
    history.goBack();
  };

  return { _navigate, _getParam, _goBack, _replace, _getLocation, _getMatch };
};

export default useNavigation;
