import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Schema } from "rsuite";
import {
  Checkbox,
  CheckboxGroup,
  CheckPicker,
  Row,
  Col,
  Grid,
  Message,
  SelectPicker,
  Input,
  InputNumber,
  Button,
  Notification,
  Alert,
} from "rsuite";
import Loader from "../../../components/admin/Loader";

import Search from "../../../components/admin/Search";
import ErrorInput from "../../../components/admin/ErrorInput";
const API = new APLICLASS();

const CuponAgregar = () => {
  const navigation = useNavigation();
  const [loader, setLoader] = useStateIfMounted(false);

  const { StringType, NumberType, ObjectType, ArrayType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [nombre, setnombre] = useStateIfMounted("");
  const [nombreError, setnombreError] = useStateIfMounted(false);

  const nombreModel = Schema.Model({
    nombre: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatenombre();
  }, [nombre]);
  const _validatenombre = () => {
    let errors = nombreModel.check({
      nombre: nombre,
    });
    if (errors.nombre.hasError) {
      let errorInfo = {
        hasError: errors.nombre.hasError,
        error: errors.nombre.hasError ? errors.nombre.errorMessage : "",
      };
      setnombreError(errorInfo);
    } else {
      setnombreError(false);
    }
  };
  // **********************************************************

  // **********************************************************
  const [monto, setmonto] = useStateIfMounted("");
  const [montoError, setmontoError] = useStateIfMounted(false);
  const montoModel = Schema.Model({
    monto: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatemonto();
  }, [monto]);
  const _validatemonto = () => {
    let errors = montoModel.check({
      monto: monto,
    });
    if (errors.monto.hasError) {
      let errorInfo = {
        hasError: errors.monto.hasError,
        error: errors.monto.hasError ? errors.monto.errorMessage : "",
      };
      setmontoError(errorInfo);
    } else {
      setmontoError(false);
    }
  };
  // **********************************************************

  const _validate = () => {
    if (!nombreError && nombre != "") {
      return _save();
    }

    Notification["error"]({
      title: "Error en el formulario",
      description: <span>Verifica la validación del formulario</span>,
    });

    _validatenombre();
  };

  const _save = async () => {
    setLoader(true);

    let request = {
      nombre: nombre,
      monto: monto,
    };

    const { data } = await API.saveCupon(request);

    if (data.status === true) {
      Alert.success("Registro agregado exitosamente.");
      navigation._navigate(`/cupones/`);
    } else {
      Alert.error("Hubo un error al agregar el registro.");
    }

    setLoader(false);
  };

  return (
    <MainLayout>
      <SectionTitle
        title="Agregar cupón"
        caption="Ingresa los campos requeridos para crear un nuevo cupón"
      ></SectionTitle>

      <Row>
        <Col xs={12}>
          <div className="input__item">
            <span>Nombre</span>
            <Input
              placeholder="Nombre del cupón"
              className={nombreError ? "input-error" : "input-no-error"}
              value={nombre}
              onChange={(val) => setnombre(val)}
            />
            {nombreError && <ErrorInput text={nombreError.error} />}
          </div>
        </Col>

        <Col xs={12}>
          <div className="input__item">
            <span>Monto de descuento</span>
            <SelectPicker
              searchable={false}
              value={monto}
              onChange={(val) => setmonto(val)}
              data={[
                {
                  label: "10%",
                  value: "0.10",
                },
                {
                  label: "20%",
                  value: "0.20",
                },
                {
                  label: "30%",
                  value: "0.30",
                },
                {
                  label: "40%",
                  value: "0.40",
                },
                {
                  label: "50%",
                  value: "0.50",
                },
                {
                  label: "60%",
                  value: "0.60",
                },
                {
                  label: "70%",
                  value: "0.70",
                },
                {
                  label: "80%",
                  value: "0.80",
                },
                {
                  label: "90%",
                  value: "0.90",
                },
                {
                  label: "100%",
                  value: "1",
                },
              ]}
              style={{ width: "100%" }}
              placeholder="Selecciona el monto de descuento"
              className={montoError ? "input-error" : "input-no-error"}
            />
            {montoError && <ErrorInput text={montoError.error} />}
          </div>
        </Col>
        <Col xs={24}>
          <div
            className="input__item"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="input__item" style={{ marginTop: 35 }}>
              <Button
                block
                appearance="primary"
                size="lg"
                onClick={() => _validate()}
                style={{ width: 250 }}
              >
                Guardar cupón
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default CuponAgregar;
