import React, { useEffect } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loading from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import imagePath from "../../../constants/pathImages";
import { Badge, Table } from "react-bootstrap";
import {
  Message,
  Button,
  IconButton,
  Icon,
  Tag,
  SelectPicker,
  Alert,
  Row,
  Col,
  Input,
  Modal,
} from "rsuite";
import Search from "../../../components/admin/Search";
import ImageDefault from "../../../assets/img/default.jpg";
import FullLayout from "../../../components/admin/Layout/full";

const API = new APLICLASS();

const PuntoDeVenta = () => {
  const [loader, setLoader] = useStateIfMounted(true);
  const [categoriaFiltro, setCategoriaFiltro] = useStateIfMounted(false);

  const [productos, setProductos] = useStateIfMounted([]);
  const [emptySearchResult, setEmptySearchResult] = useStateIfMounted(false);
  const [emptySearchResultCliente, setEmptySearchResultCliente] =
    useStateIfMounted(false);

  const [categorias, setCategorias] = useStateIfMounted([]);
  const [productosFiltrados, setProductosFiltrados] = useStateIfMounted([]);

  const [cart, setCart] = useStateIfMounted([]);
  const [impuestos, setImpuestos] = useStateIfMounted(0);

  const [search, setSearch] = useStateIfMounted("");

  const [modalClientes, setModalClientes] = useStateIfMounted(false);
  const [modalEnvio, setModalEnvio] = useStateIfMounted(false);
  const [envio, setEnvio] = useStateIfMounted("");

  const [modalDescuento, setModalDescuento] = useStateIfMounted(false);
  const [descuento, setDescuento] = useStateIfMounted(0);

  const [clientes, setClientes] = useStateIfMounted([]);
  const [descuentos, setDescuentos] = useStateIfMounted([]);

  const [cupones, setCupones] = useStateIfMounted([]);
  const [cuponSeleccionado, setCuponSeleccionado] = useStateIfMounted(false);
  const [modalCupones, setModalCupones] = useStateIfMounted(false);

  const [clientesFiltrados, setClientesFiltrados] = useStateIfMounted([]);
  const [clienteSeleccionado, setClienteSeleccionado] =
    useStateIfMounted(false);

  const [loaderVenta, setLoaderVenta] = useStateIfMounted(false);
  const [searchCliente, setSearchCliente] = useStateIfMounted("");

  const navigation = useNavigation();

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    await _getProductos();
    await _getCategorias();
  };

  const _getProductos = async () => {
    setLoader(true);
    const { data } = await API.getProductos();
    setProductos(data);
    setProductosFiltrados(data);
    setLoader(false);
  };

  const _search = () => {
    setEmptySearchResult(false);
    if (search != "") {
      let resFiltrados = productosFiltrados.filter((item) => {
        return item.nombre.toLowerCase().includes(search.toLowerCase());
      });
      if (resFiltrados.length > 0) {
        setProductosFiltrados(resFiltrados);
      } else {
        setEmptySearchResult(true);
      }
    } else {
      setProductosFiltrados(productos);
    }
  };

  const _getCategorias = async () => {
    setLoader(true);
    const { data } = await API.getCategorias();

    if (data.length > 0) {
      const dataCategorias = [];
      data.map((item) => {
        let itemParsed = {
          label: item.nombre,
          value: item.id,
        };
        dataCategorias.push(itemParsed);
      });
      setCategorias(dataCategorias);
    }
    setLoader(false);
  };

  useEffect(() => {
    _search();
  }, [search]);

  const _setSearch = (val) => {
    setSearch(val);
  };

  useEffect(() => {
    if (categoriaFiltro !== false) {
      _filtrarPorCategoria();
    }
  }, [categoriaFiltro]);

  const _setCategoriaFiltro = (val) => {
    if (val == null || val == undefined) {
      setCategoriaFiltro(false);
      _getProductos();
    } else {
      setCategoriaFiltro(val);
    }
  };

  const _filtrarPorCategoria = async () => {
    setLoader(true);
    const { data } = await API.getProductosFiltradoPorCategoria(
      categoriaFiltro
    );

    if (data !== false) {
      if (Array.isArray(data)) {
        setProductos(data);
        setProductosFiltrados(data);
      }
    } else {
      setProductos([]);
      setProductosFiltrados([]);
    }
    setLoader(false);
  };

  const _delete = async (id) => {
    const windowAlert = window.confirm(
      "Estás seguro que deseas eliminar este producto?"
    );
    if (windowAlert == true) {
      const request = {
        id_producto: id,
      };
      const { data } = await API.deleteProducto(request);
      if (data.status === true) {
        Alert.success("Registro eliminado exitosamente.");
        if (categoriaFiltro !== false) {
          _filtrarPorCategoria();
        } else {
          _getProductos();
        }
      } else {
        Alert.error("Ocurrió un error al borrar el registro.");
      }
    }
  };

  //Clientes
  useEffect(() => {
    _getClientes();
  }, []);

  const _getClientes = async () => {
    const { data } = await API.getClientes();
    if (data !== false) {
      if (Array.isArray(data)) {
        setClientes(data);
        setClientesFiltrados(data);
      }
    }

    setLoader(false);
  };

  const _seleccionarCliente = (item) => {
    setClienteSeleccionado(item);
    setModalClientes(false);
  };

  const _seleccionarCupon = (item) => {
    setCuponSeleccionado(item);
    setModalCupones(false);
  };

  //Cupones
  useEffect(() => {
    _getCupones();
  }, []);

  const _getCupones = async () => {
    const { data } = await API.getCupones();
    if (data !== false) {
      if (Array.isArray(data)) {
        setCupones(data);
      }
    }

    setLoader(false);
  };

  //Carrito
  const _addToCart = (
    item,
    cantidad,
    portada,
    tieneOferta,
    precioCobrar,
    id_vehiculo,
    color,
    comments
  ) => {
    let itemCantidad = {
      ...item,
      cantidad: cantidad,
      portada: portada,
      tieneOferta: tieneOferta,
      precioCobrar: precioCobrar,
      id_vehiculo: id_vehiculo,
      precioEnvio: item.precio_envio,
      color: color,
      comments: comments,
    };
    let newCart = [...cart, itemCantidad];
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    Alert.success(`${item.nombre}(${cantidad}) was added to the cart`);
  };

  useEffect(() => {
    _makeDescuentos();
  }, []);

  const _makeDescuentos = () => {
    let descuentosArr = [];
    for (let i = 0; i < 101; i++) {
      let valueSet = i < 10 ? "0.0" + i : "0." + i;
      let descuentoItem = { label: `${i}%`, value: valueSet };

      descuentosArr.push(descuentoItem);
    }
    setDescuentos(descuentosArr);
  };

  const _getItems = () => {
    return cart;
  };

  const _getTotal = () => {
    let newTotal = 0;
    newTotal = cart.reduce(
      (newTotal, item) => newTotal + item.precioCobrar * item.cantidad,
      0
    );
    return newTotal.toFixed(2);
  };

  const _getTotalParsed = () => {
    let newTotal = 0;
    newTotal = cart.reduce(
      (newTotal, item) => newTotal + item.precioCobrar * item.cantidad,
      0
    );
    return parseFloat(newTotal);
  };

  const _getTotalShipping = () => {
    let totalShipping = 0;
    totalShipping = cart.reduce(
      (totalShipping, item) => totalShipping + item.precioEnvio * item.cantidad,
      0
    );

    return totalShipping.toFixed(2);
  };

  const _getTotalShippingParsed = () => {
    let totalShipping = 0;
    totalShipping = cart.reduce(
      (totalShipping, item) => totalShipping + item.precioEnvio * item.cantidad,
      0
    );

    return parseFloat(totalShipping);
  };

  const _getTotalOrder = () => {
    return _getTotalOrderParsed().toFixed(2);
  };

  const _getTotalOrderParsed = () => {
    let totalItems = _getTotalParsed();
    let totalShipping = _getTotalShippingParsed();
    let totalFinal = totalItems;

    if (cuponSeleccionado !== false) {
      let descuentoCupon = totalItems * cuponSeleccionado.monto;
      totalFinal = totalFinal - descuentoCupon;
    }

    if (descuento != 0) {
      let descuentoManual = totalItems * descuento;
      totalFinal = totalFinal - descuentoManual;
    }

    return parseFloat(totalFinal + totalShipping);
  };

  const _agregarImpuestos = () => {
    let tasaTextas = 8.25;
    let totalPedido = _getTotalParsed();
    let totalMasImpuestos = (totalPedido * tasaTextas) / 100;

    setImpuestos(parseFloat(totalMasImpuestos));
  };

  const _quitarImpuestos = () => {
    setImpuestos(0);
  };

  const _getImpuestos = () => {
    if (impuestos > 0) {
      return impuestos.toFixed(2);
    } else {
      return 0;
    }
  };

  const _getImpuestosParsed = () => {
    if (impuestos > 0) {
      return parseFloat(impuestos);
    } else {
      return 0;
    }
  };

  const _getDescuentoPorCupon = () => {
    return _getDescuentoPorCuponParsed().toFixed(2);
  };

  const _getDescuentoPorCuponParsed = () => {
    if (cuponSeleccionado !== false) {
      let totalItems = _getTotalParsed();
      let totalFinal = totalItems;
      let descuento = totalFinal * cuponSeleccionado.monto;
      totalFinal = totalFinal - descuento;
      return parseFloat(descuento);
    }

    return 0;
  };

  const _getDescuentoPorDescuentoManual = () => {
    return _getDescuentoPorDescuentoManualParsed().toFixed(2);
  };

  const _getDescuentoPorDescuentoManualParsed = () => {
    if (descuento != 0) {
      let totalItems = _getTotalParsed();
      let totalFinal = totalItems;
      let descuentoTotal = totalFinal * descuento;
      totalFinal = totalFinal - descuentoTotal;
      return parseFloat(descuentoTotal);
    }

    return 0;
  };

  const _removeFromCart = (index) => {
    cart.splice(index, 1);
    setCart([...cart]);
    localStorage.setItem("cart", JSON.stringify([...cart]));
  };

  const _cleanCart = () => {
    setCart([]);
    setClienteSeleccionado(false);
    setCuponSeleccionado(false);
    setDescuento(0);
    localStorage.setItem("cart", JSON.stringify([]));
  };

  const _getItemsCount = (item) => {
    return cart.length;
  };

  const _searchCliente = () => {
    setEmptySearchResultCliente(false);
    if (searchCliente != "") {
      let resFiltrados = clientes.filter((item) => {
        return item.nombre.toLowerCase().includes(searchCliente.toLowerCase());
      });
      if (resFiltrados.length > 0) {
        setClientesFiltrados(resFiltrados);
      } else {
        setEmptySearchResultCliente(true);
        setClientesFiltrados(resFiltrados);
      }
    } else {
      setClientesFiltrados(clientes);
    }
  };

  useEffect(() => {
    _searchCliente();
  }, [searchCliente]);

  const _setSearchCliente = (val) => {
    setSearchCliente(val);
  };

  //Venta
  const _saveVenta = async (pagoData) => {
    setLoaderVenta(true);
    const request = {
      telefono: null,
      direccion_calle: null,
      direccion_extra: null,
      ciudad: null,
      id_cliente: clienteSeleccionado.id,
      zip: null,
      email: clienteSeleccionado.email,
      total: _getTotalOrderParsed(),
      nombre: clienteSeleccionado.nombre,
      apellidos: clienteSeleccionado.apellidos,
      envio_total: _getTotalShippingParsed(),
      productos: _getItems(),
      pago: null,
      tipo_pago: "TERMINAL",
      estado: null,
      pais: null,
      impuestos: _getImpuestosParsed(),
      subtotal: _getTotalParsed(),
      venta_en_pos: true,
      cupon: cuponSeleccionado != false ? cuponSeleccionado.id : null,
      cupon_total: _getDescuentoPorCuponParsed(),
      descuento: descuento,
      descuento_total: _getDescuentoPorDescuentoManualParsed(),
    };

    const { data } = await API.saveVenta(request);
    if (data.status === true) {
      Alert.success(`Venta guardada exitosamente`);
      _cleanCart();
    }

    setLoaderVenta(false);
  };

  const ModalClienteCMP = () => {
    return (
      <Modal
        overflow={true}
        show={modalClientes}
        onHide={() => setModalClientes(false)}
      >
        <Modal.Header>
          <Modal.Title>Selecciona al cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(Array.isArray(clientesFiltrados) && clientesFiltrados.length > 0) ||
          emptySearchResultCliente ? (
            <div>
              {/* buscador */}
              <div style={{ marginBottom: 20 }}>
                <Search
                  value={searchCliente}
                  updateInputFunction={_setSearchCliente}
                ></Search>
              </div>

              {clientesFiltrados.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      borderBottom: "1px solid #ccc",
                      paddingBottom: 10,
                    }}
                  >
                    <h6>
                      {item.nombre} {item.apellidos}
                    </h6>
                    <Button onClick={() => _seleccionarCliente(item)} size="xs">
                      Seleccionar
                    </Button>
                  </div>
                );
              })}
              {emptySearchResultCliente && (
                <span>
                  No encontramos clientes con tus criterios de búsqueda
                </span>
              )}
            </div>
          ) : (
            <div>
              <span>No encontramos clientes</span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalClientes(false)} appearance="subtle">
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalCuponesCMP = () => {
    return (
      <Modal
        overflow={true}
        show={modalCupones}
        onHide={() => setModalCupones(false)}
      >
        <Modal.Header>
          <Modal.Title>Selecciona el cupón</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Array.isArray(cupones) && cupones.length > 0 ? (
            <div>
              {cupones.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 10,
                      borderBottom: "1px solid #ccc",
                      paddingBottom: 10,
                    }}
                  >
                    <h6>
                      {item.nombre} - {parseFloat(item.monto) * 100}%
                    </h6>
                    <Button onClick={() => _seleccionarCupon(item)} size="xs">
                      Seleccionar
                    </Button>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <span>No encontramos cupones</span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalCupones(false)} appearance="subtle">
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ModalEnvioCMP = () => {
    return (
      <Modal
        overflow={true}
        show={modalEnvio}
        onHide={() => setModalEnvio(false)}
      >
        <Modal.Header>
          <Modal.Title>Ingresa el monto del envío</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form__item">
            <span className="form__item__label">Name</span>
            <Input
              value={envio}
              onChange={(val) => setEnvio(val)}
              placeholder="Envío"
            ></Input>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setModalEnvio(false)} color="primary">
              GUARDAR
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const ModalDescuentoCMP = () => {
    return (
      <Modal
        overflow={true}
        show={modalDescuento}
        onHide={() => setModalDescuento(false)}
      >
        <Modal.Header>
          <Modal.Title>Selecciona el monto del descuento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input__item">
            <div style={{ marginBottom: 5 }}>Monto del descuento</div>
            <SelectPicker
              searchable={true}
              value={descuento}
              onChange={(val) => setDescuento(val)}
              data={descuentos}
              style={{ width: "100%" }}
              placeholder="Selecciona el monto de descuento"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button onClick={() => setModalDescuento(false)} color="primary">
              GUARDAR
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <FullLayout
      section="Punto de venta"
      bread={[
        {
          link: "/",
          name: "Inicio",
          active: false,
        },
        {
          link: false,
          name: "Pacientes",
          active: true,
        },
      ]}
    >
      {/* Modals */}
      <ModalClienteCMP></ModalClienteCMP>
      <ModalCuponesCMP></ModalCuponesCMP>
      <ModalEnvioCMP></ModalEnvioCMP>
      <ModalDescuentoCMP></ModalDescuentoCMP>
      {/* /Modals */}

      <Row>
        {/* ==== LEFT ===== */}
        <Col xs={16}>
          <div className="pos__productos">
            <div
              style={{
                display: "flex",
                alignImtes: "center",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <h2>Productos</h2>

              {/* ==== SEARCH ===== */}
              <div className="bar-actions__left__search" style={{ width: 350 }}>
                <Search
                  value={search}
                  updateInputFunction={_setSearch}
                ></Search>
              </div>
              {/* ==== /SEARCH ===== */}
            </div>

            {/* ==== PRODUCTOS ===== */}
            {Array.isArray(productosFiltrados) &&
            productosFiltrados.length > 0 ? (
              <div>
                {productosFiltrados.map((item, index) => {
                  const IMAGEN = () => {
                    if (item.portada !== null) {
                      return imagePath + item.portada;
                    } else {
                      return ImageDefault;
                    }
                  };
                  return (
                    <div
                      className="producto-pos"
                      onClick={() =>
                        _addToCart(
                          item,
                          1,
                          item.portada,
                          item.precio_oferta !== null ? true : false,
                          item.precio_oferta !== null
                            ? item.precio_oferta
                            : item.precio,
                          null,
                          null,
                          null
                        )
                      }
                    >
                      <div className="producto-pos__info">
                        <div
                          style={{
                            backgroundImage: `url(${IMAGEN()})`,
                            width: "60px",
                            height: "60px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            marginRight: 10,
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                          }}
                        ></div>
                        <di>
                          <h6>{item.nombre}</h6>
                          <spa>{item.categoria}</spa>
                        </di>
                      </div>
                      <div>
                        {item.precio_oferta !== null ? (
                          <div className="producto-pos__precio">
                            <h4 className="producto-pos__precio-regular-tachado">
                              ${item.precio}
                            </h4>
                            <h4 className="producto-pos__precio-oferta">
                              ${item.precio_oferta}
                            </h4>
                          </div>
                        ) : (
                          <div className="producto-pos__precio">
                            <h4 className="producto-pos__precio-regular">
                              ${item.precio}
                            </h4>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>No encontramos productos disponibles</div>
            )}
          </div>
          {/* ==== /PRODUCTOS ===== */}
        </Col>
        {/* ==== /LEFT ===== */}

        {/* ==== RIGHT ===== */}
        <Col xs={8}>
          <div className="pos__total">
            {/* Productos en el carrito */}
            <h6 className="bold">En el carrito</h6>
            <div className="pos__total__items">
              {_getItemsCount() > 0 ? (
                <div>
                  {cart.map((item, index) => {
                    return (
                      <div className="pos__total__items__item">
                        <div className="pos__total__items__item__name">
                          <h6>{item.nombre}</h6>
                        </div>

                        <div className="pos__total__items__item__price">
                          <h6>${item.precioCobrar}</h6>
                          <IconButton
                            onClick={() => _removeFromCart(index)}
                            color="red"
                            icon={<Icon icon="trash-o" />}
                            circle
                            size="xs"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>
                  <span>Agrega productos al carrito</span>
                </div>
              )}
            </div>
            {/* /Productos en el carrito */}

            {/* Cliente */}
            <div
              className="pos__total__cliente"
              style={{
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              {clienteSeleccionado === false ? (
                <div style={{ width: "100%" }}>
                  <Button
                    color="cyan"
                    onClick={() => setModalClientes(true)}
                    block
                  >
                    Selecciona el cliente (requerido)
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignImtes: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: 5,
                  }}
                >
                  <div>
                    <h6 className="bold">Cliente</h6>
                    <h6 style={{ fontWeight: "bold" }}>
                      {clienteSeleccionado.nombre} {""}{" "}
                      {clienteSeleccionado.apellidos}
                    </h6>
                  </div>
                  <div>
                    <IconButton
                      onClick={() => setModalClientes(true)}
                      color="blue"
                      icon={<Icon icon="edit" />}
                      circle
                      size="xs"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* /Cliente */}

            {/* Cupón */}
            <div
              className="pos__total__cupon"
              style={{
                marginTop: 0,
                paddingTop: 0,
              }}
            >
              {cuponSeleccionado === false ? (
                <div style={{ width: "100%" }}>
                  <Button
                    color="cyan"
                    onClick={() => setModalCupones(true)}
                    block
                  >
                    Selecciona el cupón
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignImtes: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: 5,
                  }}
                >
                  <div>
                    <h6 className="bold">Cupón</h6>
                    <h6 style={{ fontWeight: "bold", color: "red" }}>
                      {cuponSeleccionado.nombre} {" - "}
                      {cuponSeleccionado.monto * 100}%
                    </h6>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <IconButton
                      style={{ marginRight: 10 }}
                      onClick={() => setCuponSeleccionado(false)}
                      color="red"
                      icon={<Icon icon="trash" />}
                      circle
                      size="xs"
                    />
                    <IconButton
                      onClick={() => setModalCupones(true)}
                      color="blue"
                      icon={<Icon icon="edit" />}
                      circle
                      size="xs"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* /Cupón */}

            {/* Descuento */}
            <div
              className="pos__total__cupon"
              style={{
                marginTop: 0,
                paddingTop: 0,
              }}
            >
              {descuento === 0 ? (
                <div style={{ width: "100%" }}>
                  <Button
                    color="cyan"
                    onClick={() => setModalDescuento(true)}
                    block
                  >
                    Selecciona el descuento
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignImtes: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: 5,
                  }}
                >
                  <div>
                    <h6 className="bold">Descuento</h6>
                    <h6 style={{ fontWeight: "bold", color: "red" }}>
                      {" - "}
                      {descuento * 100}%
                    </h6>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <IconButton
                      style={{ marginRight: 10 }}
                      onClick={() => setDescuento(0)}
                      color="red"
                      icon={<Icon icon="trash" />}
                      circle
                      size="xs"
                    />
                    <IconButton
                      onClick={() => setModalDescuento(true)}
                      color="blue"
                      icon={<Icon icon="edit" />}
                      circle
                      size="xs"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* /Descuento */}

            {/* Envío */}
            <div
              style={{
                marginTop: 0,
                paddingTop: 5,
              }}
            >
              <h6 className="bold"> Envío</h6>
              <div
                style={{
                  display: "flex",
                  alignImtes: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <h6 style={{ fontWeight: "bold" }}>${_getTotalShipping()}</h6>
                </div>
              </div>
            </div>
            {/* /Envío */}

            {/* Total */}
            <div className="pos__total__total">
              <h4>Total</h4>
              <h3>${_getTotalOrder()}</h3>
            </div>
            {/* /Total */}

            {/* Terminar */}
            {clienteSeleccionado !== false && (
              <div className="pos__total__pagar">
                <Button
                  onClick={() => _saveVenta()}
                  block
                  color="green"
                  loading={loaderVenta}
                >
                  TERMINAR VENTA
                </Button>
              </div>
            )}
            {/* /Terminar */}
          </div>
        </Col>
        {/* ==== /RIGHT ===== */}
      </Row>
    </FullLayout>
  );
};

export default PuntoDeVenta;
