import axios from "axios";
import api from "../constants/api";

export default class API {
  async getCategorias() {
    return axios.get(api, {
      params: {
        ot: "getCategorias",
        app: "admin",
      },
    });
  }

  async getCupones() {
    return axios.get(api, {
      params: {
        ot: "getCupones",
        app: "admin",
      },
    });
  }

  async getUser(id) {
    return axios.get(api, {
      params: {
        ot: "getUserAdmin",
        app: "admin",
        id,
      },
    });
  }

  async loginAdmin(email, password) {
    return axios.get(api, {
      params: {
        ot: "loginAdmin",
        app: "admin",
        email,
        password,
      },
    });
  }

  async getReporteProduccion() {
    return axios.get(api, {
      params: {
        ot: "getReporteProduccion",
        app: "admin",
      },
    });
  }

  async getPosts() {
    return axios.get(api, {
      params: {
        ot: "getPosts",
        app: "admin",
      },
    });
  }

  async getClientes() {
    return axios.get(api, {
      params: {
        ot: "getClientes",
        app: "admin",
      },
    });
  }

  async getCupon(id) {
    return axios.get(api, {
      params: {
        ot: "getCupon",
        app: "admin",
        id: id,
      },
    });
  }

  async getPost(id) {
    return axios.get(api, {
      params: {
        ot: "getPost",
        app: "admin",
        id: id,
      },
    });
  }

  async getVentas() {
    return axios.get(api, {
      params: {
        ot: "getVentas",
        app: "admin",
      },
    });
  }

  async getVentasDelMes(fecha) {
    return axios.get(api, {
      params: {
        ot: "getReporteDeVentasPorMes",
        app: "admin",
        fecha,
      },
    });
  }

  async getVentasDeLaQuincena(fecha) {
    return axios.get(api, {
      params: {
        ot: "getReporteDeVentasPorQuincena",
        app: "admin",
        fecha,
      },
    });
  }

  async getVentasDeLaSemana(fecha) {
    return axios.get(api, {
      params: {
        ot: "getReporteDeVentasPorSemana",
        app: "admin",
        fecha,
      },
    });
  }

  async getVentasDelDia(fecha) {
    return axios.get(api, {
      params: {
        ot: "getReporteDeVentasPorDia",
        app: "admin",
        fecha,
      },
    });
  }

  async getVentasHistorial(fecha) {
    return axios.get(api, {
      params: {
        ot: "getReporteDeVentasPorHistorial",
        app: "admin",
        fecha,
      },
    });
  }

  async getVenta(id) {
    return axios.get(api, {
      params: {
        ot: "getVenta",
        app: "admin",
        id_venta: id,
      },
    });
  }

  async getCliente(id) {
    return axios.get(api, {
      params: {
        ot: "getCliente",
        app: "admin",
        id_cliente: id,
      },
    });
  }

  async getClienteCompras(id) {
    return axios.get(api, {
      params: {
        ot: "getClienteCompras",
        app: "admin",
        id_cliente: id,
      },
    });
  }

  async getClienteReporteDeCompras(id) {
    return axios.get(api, {
      params: {
        ot: "getReporteDeVentasClienteHistorial",
        app: "admin",
        id_cliente: id,
      },
    });
  }

  async getMensajes(id) {
    return axios.get(api, {
      params: {
        ot: "getMensajes",
        app: "admin",
        id_venta: id,
      },
    });
  }

  async getCliente(id) {
    return axios.get(api, {
      params: {
        ot: "getCliente",
        app: "admin",
        id,
      },
    });
  }

  async getVehiculos() {
    return axios.get(api, {
      params: {
        ot: "getVehiculos",
        app: "admin",
      },
    });
  }

  async getProductos() {
    return axios.get(api, {
      params: {
        ot: "getProductos",
        app: "admin",
      },
    });
  }

  async getProductosFiltradoPorCategoria(id_categoria) {
    return axios.get(api, {
      params: {
        ot: "getProductosFiltradoPorCategoria",
        app: "admin",
        id_categoria,
      },
    });
  }

  async getProductoDetalle(id) {
    return axios.get(api, {
      params: {
        ot: "getProducto",
        app: "admin",
        id,
      },
    });
  }

  async getCategoriaDetalle(id) {
    return axios.get(api, {
      params: {
        ot: "getCategoria",
        app: "admin",
        id,
      },
    });
  }

  async getVehiculosMarcas() {
    return axios.get(api, {
      params: {
        ot: "getVehiculosMarcas",
        app: "admin",
      },
    });
  }

  async getGaleriaProducto(id) {
    return axios.get(api, {
      params: {
        ot: "getGaleriaProducto",
        app: "admin",
        id,
      },
    });
  }

  async getVehiculoDetalle(id) {
    return axios.get(api, {
      params: {
        ot: "getVehiculoDetalle",
        app: "admin",
        id,
      },
    });
  }

  async getAnosData() {
    return axios.get(api, {
      params: {
        ot: "getAnosData",
        app: "admin",
      },
    });
  }

  async saveProducto(data) {
    return axios.post(api, {
      params: {
        ot: "SaveProducto",
        app: "admin",
        data,
      },
    });
  }

  async saveMensaje(data) {
    return axios.post(api, {
      params: {
        ot: "SaveMensaje",
        app: "admin",
        data,
      },
    });
  }

  async saveCategoria(data) {
    return axios.post(api, {
      params: {
        ot: "SaveCategoria",
        app: "admin",
        data,
      },
    });
  }

  async updateGuiaVenta(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateGuiaVenta",
        app: "admin",
        data,
      },
    });
  }

  async updateRevendedor(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateRevendedor",
        app: "admin",
        data,
      },
    });
  }

  async updateEstatusVenta(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateEstatusVenta",
        app: "admin",
        data,
      },
    });
  }

  async saveCupon(data) {
    return axios.post(api, {
      params: {
        ot: "SaveCupon",
        app: "admin",
        data,
      },
    });
  }

  async updateProducto(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateProducto",
        app: "admin",
        data,
      },
    });
  }

  async updateCupon(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateCupon",
        app: "admin",
        data,
      },
    });
  }

  async updatePost(data) {
    return axios.post(api, {
      params: {
        ot: "UpdatePost",
        app: "admin",
        data,
      },
    });
  }

  async updateVehiculo(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateVehiculo",
        app: "admin",
        data,
      },
    });
  }

  async updateCategoria(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateCategoria",
        app: "admin",
        data,
      },
    });
  }

  async removeImagenPortada(data) {
    return axios.post(api, {
      params: {
        ot: "RemoveImagenPortada",
        app: "admin",
        data,
      },
    });
  }

  async deleteCategoria(data) {
    return axios.post(api, {
      params: {
        ot: "DeleteCategoria",
        app: "admin",
        data,
      },
    });
  }

  async deleteMensaje(data) {
    return axios.post(api, {
      params: {
        ot: "DeleteMensaje",
        app: "admin",
        data,
      },
    });
  }

  async deleteVehiculo(data) {
    return axios.post(api, {
      params: {
        ot: "DeleteVehiculo",
        app: "admin",
        data,
      },
    });
  }

  async saveVehiculo(data) {
    return axios.post(api, {
      params: {
        ot: "SaveVehiculo",
        app: "admin",
        data,
      },
    });
  }

  async saveVenta(data) {
    return axios.post(api, {
      params: {
        ot: "saveVenta",
        app: "web",
        data,
      },
    });
  }

  async deleteProducto(data) {
    return axios.post(api, {
      params: {
        ot: "DeleteProducto",
        app: "admin",
        data,
      },
    });
  }

  async asignarImagenDestacadaProductoGaleria(data) {
    return axios.post(api, {
      params: {
        ot: "AsignarImagenDestacadaProducto",
        app: "admin",
        data,
      },
    });
  }

  async deleteImagenGaleriaProducto(data) {
    return axios.post(api, {
      params: {
        ot: "DeleteImagenGaleriaProducto",
        app: "admin",
        data,
      },
    });
  }

  async saveCliente(data) {
    return axios.post(api, {
      params: {
        ot: "SaveCliente",
        app: "admin",
        data,
      },
    });
  }

  async updateCliente(data) {
    return axios.post(api, {
      params: {
        ot: "UpdateCliente",
        app: "admin",
        data,
      },
    });
  }

  async deleteCliente(data) {
    return axios.post(api, {
      params: {
        ot: "DeleteCliente",
        app: "admin",
        data,
      },
    });
  }

  async bloquearCliente(data) {
    return axios.post(api, {
      params: {
        ot: "BloquearCliente",
        app: "admin",
        data,
      },
    });
  }

  async desBloquearCliente(data) {
    return axios.post(api, {
      params: {
        ot: "DesbloquearCliente",
        app: "admin",
        data,
      },
    });
  }
}
