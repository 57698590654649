import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Schema } from "rsuite";
import {
  Checkbox,
  CheckboxGroup,
  CheckPicker,
  Row,
  Col,
  Grid,
  Message,
  SelectPicker,
  Input,
  InputNumber,
  Button,
  Notification,
  Alert,
} from "rsuite";
import Loader from "../../../components/admin/Loader";

import Search from "../../../components/admin/Search";
import ErrorInput from "../../../components/admin/ErrorInput";
const API = new APLICLASS();

const CategoriaAgregar = () => {
  const navigation = useNavigation();
  const [loader, setLoader] = useStateIfMounted(false);
  const [estatus, setEstatus] = useStateIfMounted("1");

  const { StringType, NumberType, ObjectType, ArrayType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [nombre, setnombre] = useStateIfMounted("");
  const [nombreError, setnombreError] = useStateIfMounted(false);

  const nombreModel = Schema.Model({
    nombre: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validatenombre();
  }, [nombre]);
  const _validatenombre = () => {
    let errors = nombreModel.check({
      nombre: nombre,
    });
    if (errors.nombre.hasError) {
      let errorInfo = {
        hasError: errors.nombre.hasError,
        error: errors.nombre.hasError ? errors.nombre.errorMessage : "",
      };
      setnombreError(errorInfo);
    } else {
      setnombreError(false);
    }
  };
  // **********************************************************

  const _validate = () => {
    if (!nombreError && nombre != "") {
      return _save();
    }

    Notification["error"]({
      title: "Error en el formulario",
      description: <span>Verifica la validación del formulario</span>,
    });

    _validatenombre();
  };

  const _save = async () => {
    setLoader(true);

    let request = {
      nombre: nombre,
      estatus: estatus === "" ? null : estatus,
    };

    const { data } = await API.saveCategoria(request);

    if (data.status === true) {
      Alert.success("Registro agregado exitosamente.");
      navigation._navigate(`/categorias/${data.id}`);
    } else {
      Alert.error("Hubo un error al agregar el registro.");
    }

    setLoader(false);
  };

  return (
    <MainLayout>
      <SectionTitle
        title="Agregar categoría"
        caption="Ingresa los campos requeridos para crear una nueva categoría"
      ></SectionTitle>

      <Row gutter={48}>
        <Col xs={16}>
          <div className="input__item">
            <span>Nombre</span>
            <Input
              placeholder="Nombre del producto"
              className={nombreError ? "input-error" : "input-no-error"}
              value={nombre}
              onChange={(val) => setnombre(val)}
            />
            {nombreError && <ErrorInput text={nombreError.error} />}
          </div>

          <div className="input__item">
            <span>Estatus</span>
            <SelectPicker
              searchable={false}
              value={estatus}
              onChange={(val) => setEstatus(val)}
              data={[
                {
                  label: "Activo",
                  value: "1",
                },
                {
                  label: "Deshabilitado",
                  value: "0",
                },
              ]}
              style={{ width: "100%" }}
              placeholder="Selecciona el estatus"
            />
          </div>
        </Col>
        <Col xs={8}>
          <div className="input__item" style={{ marginTop: 35 }}>
            <Button
              block
              appearance="primary"
              size="lg"
              onClick={() => _validate()}
            >
              Guardar Categoría
            </Button>
          </div>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default CategoriaAgregar;
