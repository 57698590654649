import React, { useContext } from "react";
import { Dropdown, Icon, Nav, Navbar, Breadcrumb } from "rsuite";
import UserContext from "../../../state/user";
import { Link } from "react-router-dom";
import useNavigation from "../../../hooks/useNavigation";

const MenuTop = (props) => {
  const navigation = useNavigation();
  const { user, setUser } = useContext(UserContext);

  const _logOut = () => {
    if (window.confirm("Deseas cerrar sesión?") == true) {
      localStorage.removeItem("id");
      setUser(false);
    } else {
    }
  };

  return (
    <div className="main-top">
      <Navbar appearance="inverse" className="main-top__navigation">
        <Navbar.Header>
          <a
            href="#"
            className="navbar-brand logo"
            style={{ marginLeft: 20, paddingTop: 13, fontWeight: "bold" }}
          >
            DMX PERFORMANCE ADMIN
          </a>
        </Navbar.Header>
        <Navbar.Body>
          <Nav>
            <Nav.Item
              onClick={() => navigation._navigate("/")}
              icon={<Icon icon="home" />}
            >
              Inicio
            </Nav.Item>
            <Dropdown title="Productos">
              <Dropdown.Item onClick={() => navigation._navigate("/productos")}>
                Lista
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigation._navigate("/productos/agregar")}
              >
                Agregar
              </Dropdown.Item>
            </Dropdown>
            <Dropdown title="Categorías">
              <Dropdown.Item
                onClick={() => navigation._navigate("/categorias")}
              >
                Lista
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigation._navigate("/categorias/agregar")}
              >
                {" "}
                Agregar
              </Dropdown.Item>
            </Dropdown>
            <Dropdown title="Vehículos">
              <Dropdown.Item onClick={() => navigation._navigate("/vehiculos")}>
                Lista
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigation._navigate("/productos/agregar")}
              >
                Agregar
              </Dropdown.Item>
            </Dropdown>
            <Dropdown title="Clientes">
              <Dropdown.Item onClick={() => navigation._navigate("/clientes")}>
                Lista
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigation._navigate("/clientes/agregar")}
              >
                Agregar
              </Dropdown.Item>
            </Dropdown>
            <Dropdown title="Ventas">
              <Dropdown.Item onClick={() => navigation._navigate("/ventas")}>
                Lista
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigation._navigate("/punto-de-venta")}
              >
                Agregar
              </Dropdown.Item>
            </Dropdown>

            {/* <Dropdown title="Cupones">
              <Dropdown.Item onClick={() => navigation._navigate("/cupones")}>
                Lista
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigation._navigate("/cupones/agregar")}
              >
                Agregar
              </Dropdown.Item>
            </Dropdown> */}

            <Dropdown title="Posts">
              <Dropdown.Item onClick={() => navigation._navigate("/posts")}>
                Lista
              </Dropdown.Item>
              {/* <Dropdown.Item
                onClick={() => navigation._navigate("/posts/agregar")}
              >
                Agregar
              </Dropdown.Item> */}
            </Dropdown>
          </Nav>
          <Nav pullRight>
            <Nav.Item
              onClick={() => navigation._navigate("/punto-de-venta")}
              icon={<Icon icon="credit-card" />}
            >
              Punto de venta
            </Nav.Item>
            <Nav.Item
              onSelect={() => _logOut()}
              icon={<Icon style={{ color: "red" }} icon="sign-out" />}
            >
              <span style={{ color: "red" }}>Cerrar sesión</span>
            </Nav.Item>
          </Nav>
        </Navbar.Body>
      </Navbar>
    </div>
  );
};

export default MenuTop;
