import React from "react";
import { useStateIfMounted } from "use-state-if-mounted";

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useStateIfMounted(false);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
