import React, { useEffect } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import Loading from "../../../components/admin/Loader";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import imagePath from "../../../constants/pathImages";
import { Badge, Table } from "react-bootstrap";
import {
  Message,
  Button,
  IconButton,
  Icon,
  Tag,
  SelectPicker,
  Alert,
} from "rsuite";
import Search from "../../../components/admin/Search";
import ImageDefault from "../../../assets/img/default.jpg";

const API = new APLICLASS();

const ProductoLista = () => {
  const [loader, setLoader] = useStateIfMounted(true);
  const [categoriaFiltro, setCategoriaFiltro] = useStateIfMounted(false);

  const [productos, setProductos] = useStateIfMounted([]);
  const [emptySearchResult, setEmptySearchResult] = useStateIfMounted(false);
  const [categorias, setCategorias] = useStateIfMounted([]);
  const [productosFiltrados, setProductosFiltrados] = useStateIfMounted([]);

  const [search, setSearch] = useStateIfMounted("");

  const navigation = useNavigation();

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    await _getProductos();
    await _getCategorias();
  };

  const _getProductos = async () => {
    setLoader(true);
    const { data } = await API.getProductos();
    setProductos(data);
    setProductosFiltrados(data);
    setLoader(false);
  };

  const _search = () => {
    setEmptySearchResult(false);
    if (search != "") {
      let resFiltrados = productosFiltrados.filter((item) => {
        return (
          item.nombre.toLowerCase().includes(search.toLowerCase()) ||
          item.nombre.toLowerCase().includes(search.toLowerCase()) ||
          item.id.toString().includes(search.toLowerCase())
        );
      });
      if (resFiltrados.length > 0) {
        setProductosFiltrados(resFiltrados);
      } else {
        setEmptySearchResult(true);
      }
    } else {
      setProductosFiltrados(productos);
    }
  };

  const _getCategorias = async () => {
    setLoader(true);
    const { data } = await API.getCategorias();

    if (data.length > 0) {
      const dataCategorias = [];
      data.map((item) => {
        let itemParsed = {
          label: item.nombre,
          value: item.id,
        };
        dataCategorias.push(itemParsed);
      });
      setCategorias(dataCategorias);
    }
    setLoader(false);
  };

  useEffect(() => {
    _search();
  }, [search]);

  const _setSearch = (val) => {
    setSearch(val);
  };

  useEffect(() => {
    if (categoriaFiltro !== false) {
      _filtrarPorCategoria();
    }
  }, [categoriaFiltro]);

  const _setCategoriaFiltro = (val) => {
    if (val == null || val == undefined) {
      setCategoriaFiltro(false);
      _getProductos();
    } else {
      setCategoriaFiltro(val);
    }
  };

  const _filtrarPorCategoria = async () => {
    setLoader(true);
    const { data } = await API.getProductosFiltradoPorCategoria(
      categoriaFiltro
    );

    if (data !== false) {
      if (Array.isArray(data)) {
        setProductos(data);
        setProductosFiltrados(data);
      }
    } else {
      setProductos([]);
      setProductosFiltrados([]);
    }
    setLoader(false);
  };

  const _delete = async (id) => {
    const windowAlert = window.confirm(
      "Estás seguro que deseas eliminar este producto?"
    );
    if (windowAlert == true) {
      const request = {
        id_producto: id,
      };
      const { data } = await API.deleteProducto(request);
      if (data.status === true) {
        Alert.success("Registro eliminado exitosamente.");
        if (categoriaFiltro !== false) {
          _filtrarPorCategoria();
        } else {
          _getProductos();
        }
      } else {
        Alert.error("Ocurrió un error al borrar el registro.");
      }
    }
  };

  const ListaProductos = () => {
    if (Array.isArray(productosFiltrados) && productosFiltrados.length > 0) {
      return (
        <div>
          {!emptySearchResult ? (
            <Table striped hover>
              <thead>
                <tr>
                  <th>Imagen</th>
                  <th>Nombre</th>
                  <th>Categoría</th>
                  <th>Precio</th>
                  <th>Precio oferta</th>

                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {productosFiltrados.map((item, index) => {
                  const IMAGEN = () => {
                    if (item.portada !== null) {
                      return imagePath + item.portada;
                    } else {
                      return ImageDefault;
                    }
                  };
                  return (
                    <tr>
                      <td
                        style={{
                          backgroundImage: `url(${IMAGEN()})`,
                          width: "50px",
                          height: "50px",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></td>
                      <td>{item.nombre}</td>
                      <td>{item.categoria}</td>
                      <td>
                        <Tag color="cyan">${item.precio}</Tag>
                      </td>
                      <td>
                        {item.precio_oferta === null ? (
                          <Tag color="orange">Sin oferta</Tag>
                        ) : (
                          <Tag color="red">{`$${item.precio_oferta}`}</Tag>
                        )}
                      </td>

                      <td>
                        <IconButton
                          icon={<Icon icon="edit" />}
                          circle
                          size="sm"
                          color="primary"
                          onClick={() =>
                            navigation._replace(`/productos/${item.id}`)
                          }
                        />

                        <IconButton
                          icon={<Icon icon="trash" />}
                          circle
                          size="sm"
                          color="red"
                          onClick={() => _delete(item.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div>No econtramos registros que coincidan con tu búsqueda</div>
          )}
        </div>
      );
    } else {
      return (
        <Message
          type="info"
          title="Info"
          showIcon
          description={
            <p>
              No has agregado productos aún.
              <br />
              <a
                style={{ cursor: "pointer" }}
                onClick={() => navigation._navigate("/productos/agregar")}
              >
                Puedes agregar tu primer producto aquí.
              </a>
            </p>
          }
        />
      );
    }
  };

  return (
    <MainLayout
      section="Productos"
      bread={[
        {
          link: "/",
          name: "Inicio",
          active: false,
        },
        {
          link: false,
          name: "Ventas",
          active: true,
        },
      ]}
    >
      <SectionTitle
        title={`Productos (${productosFiltrados.length})`}
        caption={
          categoriaFiltro === false ? "Lista" : "Lista filtrada por categoría"
        }
      ></SectionTitle>
      <div className="bar-actions">
        <div className="bar-actions__left">
          {/* ==== SEARCH ===== */}
          <div className="bar-actions__left__search">
            <Search value={search} updateInputFunction={_setSearch}></Search>
          </div>
          {/* ==== /SEARCH ===== */}

          {/* ==== FILTRO CATEGORIAS ===== */}
          <div className="input__item " style={{ marginTop: 0 }}>
            <SelectPicker
              searchable={true}
              data={categorias}
              style={{ width: "100%" }}
              placeholder="Selecciona la categoría"
              value={categoriaFiltro}
              onChange={(val) => _setCategoriaFiltro(val)}
            />
          </div>
          {/* ==== /FILTRO CATEGORIAS ===== */}
        </div>

        <div className="bar-actions__right">
          <Button
            appearance="primary"
            onClick={() => navigation._navigate("/productos/agregar")}
          >
            Agregar nuevo producto
          </Button>
        </div>
      </div>
      {loader ? (
        <Loading text="Cargando productos"></Loading>
      ) : (
        <div>
          <ListaProductos></ListaProductos>
        </div>
      )}
    </MainLayout>
  );
};

export default ProductoLista;
