import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import APICLASS from "../api/web";
import ScrollToTop from "../components/admin/ScrollToTop";
import Login from "../views/admin/auth/login";

const API = new APICLASS();

const PublicNavigation = (props) => {
  const navigation = useHistory;
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/login">
            {(navigation) => {
              return <Login props={navigation}></Login>;
            }}
          </Route>
          <Route path="/">
            {(navigation) => {
              return <Login props={navigation}></Login>;
            }}
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default PublicNavigation;
