import React from "react";
import { ConfigProvider } from "react-avatar";
import LockedNavigation from "./navigation/locked";
import MainNavigation from "./navigation/main";
import { UserProvider } from "./state/user";

const Main = (props) => {
  return <MainNavigation></MainNavigation>;
};

function App() {
  return (
    <div className="App">
      <UserProvider>
        <ConfigProvider colors={["red", "green", "blue"]}>
          <Main></Main>
        </ConfigProvider>
      </UserProvider>
    </div>
  );
}

export default App;
