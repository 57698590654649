import React, { useEffect, useState } from "react";
import MainLayout from "../../../components/admin/Layout/main";
import SectionTitle from "../../../components/admin/sectionTitle";
import APLICLASS from "../../../api/web";
import { useStateIfMounted } from "use-state-if-mounted";
import useNavigation from "../../../hooks/useNavigation";
import { Schema } from "rsuite";
import {
  Checkbox,
  CheckboxGroup,
  CheckPicker,
  Row,
  Col,
  Grid,
  Message,
  SelectPicker,
  Input,
  InputNumber,
  Button,
  Notification,
  Alert,
} from "rsuite";
import Loader from "../../../components/admin/Loader";

import Search from "../../../components/admin/Search";
import ErrorInput from "../../../components/admin/ErrorInput";
const API = new APLICLASS();

const VentaGuia = (props) => {
  const navigation = useNavigation();
  const [loader, setLoader] = useStateIfMounted(false);

  const { StringType, NumberType, ObjectType, ArrayType } = Schema.Types;
  const [isMount, setIsMount] = useState(true);

  // **********************************************************
  const [guia, setguia] = useStateIfMounted(props.venta.venta?.numero_guia);
  const [guiaError, setguiaError] = useStateIfMounted(false);
  const guiaModel = Schema.Model({
    guia: StringType().isRequired("Este campo es requerido"),
  });
  useEffect(() => {
    if (isMount) {
      setIsMount(false);
      return;
    }
    _validateguia();
  }, [guia]);
  const _validateguia = () => {
    let errors = guiaModel.check({
      guia: guia,
    });
    if (errors.guia.hasError) {
      let errorInfo = {
        hasError: errors.guia.hasError,
        error: errors.guia.hasError ? errors.guia.errorMessage : "",
      };
      setguiaError(errorInfo);
    } else {
      setguiaError(false);
    }
  };
  // **********************************************************

  const _validateGuia = () => {
    if (!guiaError && guia != "") {
      return _updateGuia();
    }

    Notification["error"]({
      title: "Error en el formulario",
      description: <span>Verifica la validación del formulario</span>,
    });

    _validateguia();
  };

  const _updateGuia = async () => {
    setLoader(true);

    let request = {
      guia: guia,
      id_venta: props.venta.venta.id,
    };

    const { data } = await API.updateGuiaVenta(request);

    if (data.status === true) {
      Alert.success("Registro actualizado exitosamente.");
    } else {
      Alert.error("Hubo un error al actualizar el registro.");
    }

    setLoader(false);
  };

  return (
    <div>
      <Row>
        <Col xs={24}>
          <div className="input__item">
            <span>Número de guía</span>
            <Input
              placeholder="Guía"
              className={guiaError ? "input-error" : "input-no-error"}
              value={guia}
              onChange={(val) => setguia(val)}
            />
            {guiaError && <ErrorInput text={guiaError.error} />}
          </div>
        </Col>

        <Col xs={24}>
          <div
            className="input__item"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="input__item">
              <Button
                block
                appearance="primary"
                onClick={() => _validateGuia()}
                loading={loader}
              >
                Actualizar Guía
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VentaGuia;
