import React, { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Button, ButtonGroup } from "rsuite";
import { useStateIfMounted } from "use-state-if-mounted";
import APLICLASS from "../../../api/web";
import MainLayout from "../../../components/admin/Layout/main";
import Loader from "../../../components/admin/Loader";
import useNavigation from "../../../hooks/useNavigation";
import VentaEstatus from "./estatus";
import VentaGuia from "./guia";
import VentaInfo from "./info";
import VentaMensajes from "./mensajes";

const API = new APLICLASS();

const VentaDetalle = () => {
  const componentRef = useRef();
  const { sname } = useParams();
  const history = useHistory();
  const navigation = useNavigation();
  const params = navigation._getParam();
  const [loader, setLoader] = useStateIfMounted(true);
  const [venta, setVenta] = useStateIfMounted(false);
  const [section, setSection] = useStateIfMounted("info");
  const [mensajes, setMensajes] = useStateIfMounted([]);

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    setLoader(true);
    await _getVenta();
    await _getMensajes();
    setLoader(false);
  };

  const _getVenta = async () => {
    const { data } = await API.getVenta(params.id);

    if (data !== false) {
      setVenta(data);
    }
  };

  const _getMensajes = async () => {
    const { data } = await API.getMensajes(params.id);
    setMensajes(data);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const SectionWrapper = () => {
    switch (section) {
      case "info":
        return <VentaInfo venta={venta}></VentaInfo>;
      case "mensajes":
        return (
          <VentaMensajes
            refresh={_getMensajes}
            mensajes={mensajes}
            venta={venta}
          ></VentaMensajes>
        );
      case "guia":
        return (
          <VentaGuia
            refresh={_getMensajes}
            mensajes={mensajes}
            venta={venta}
          ></VentaGuia>
        );

      case "estatus":
        return (
          <VentaEstatus
            refresh={_getMensajes}
            mensajes={mensajes}
            venta={venta}
          ></VentaEstatus>
        );
      default:
        return (
          <div>
            <h1>Venta print</h1>
            <button
              style={{ height: 500, background: "red" }}
              onClick={handlePrint}
            >
              Print this out!
            </button>
          </div>
        );
    }
  };

  useEffect(() => {
    setSection(sname);
  }, [sname]);

  return (
    <MainLayout
      backLink="/"
      section="Detalle de venta"
      bread={[
        {
          link: "/",
          name: "Inicio",
          active: false,
        },
        {
          link: "/ventas",
          name: "Ventas",
          active: false,
        },
        {
          link: false,
          name: "Detalle de venta",
          active: true,
        },
      ]}
    >
      {loader && venta !== false ? (
        <Loader text="Cargando datos de la venta..."></Loader>
      ) : (
        <div>
          <ButtonGroup style={{ marginBottom: 10 }}>
            <Button
              color={section === "info" ? "primary" : "secondary"}
              size="lg"
              onClick={() => history.push(`/ventas/${params.id}/info`)}
            >
              Detalle
            </Button>
            <Button
              color={section === "mensajes" ? "primary" : "secondary"}
              size="lg"
              onClick={() => history.push(`/ventas/${params.id}/mensajes`)}
            >
              Mensajes
            </Button>
            <Button
              color={section === "guia" ? "primary" : "secondary"}
              size="lg"
              onClick={() => history.push(`/ventas/${params.id}/guia`)}
            >
              Guía
            </Button>
            <Button
              color={section === "estatus" ? "primary" : "secondary"}
              size="lg"
              onClick={() => history.push(`/ventas/${params.id}/estatus`)}
            >
              Estatus
            </Button>
          </ButtonGroup>
          <SectionWrapper></SectionWrapper>
        </div>
      )}
    </MainLayout>
  );
};

export default VentaDetalle;
